
  .not-found {
    width: 560px;
    height: 225px;
    margin-right: -10px;
  }
  .starry-sky {
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .search-icon {
    display: inline-block;
  }
  .notfound-copy {
    color: #fff;
    position: fixed;
    top: 40px;
    right: 20%;
    text-align: right;
  }
 
  .aquib {
    font-weight: 300;
    color: #fff;
    /* border-bottom: 1.5px solid #5581d4; */
    text-decoration: none;
  }
  .aquib:hover {
    font-weight: 300;
    color: #040010;
    font-weight: 600;
    /* border-bottom: 2px solid #fff; */
    text-decoration: none;
  }

  .all-stars {
    animation: blinkblink 7s linear infinite;
  }

  
  @keyframes blinkblink {  
    50% { opacity: 0.1; }
  }
  

  @media (max-width: 647px) { 
    .moon {
      padding-top: -500px;
      display: none;
    }
    
        .not-found {
            /* width: 560px;
            height: 225px; */
            margin-right: -65px;
          }
    
  }

 