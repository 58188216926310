@charset "UTF-8";
/******************************
Template Name: Boria
Template Description: Boria - Multipurpose Responsive eCommerce HTML Template
Template Author: Jaber Hossain Shovon
Template URL: https://hasthemes.com/

=====================
    Table Of Index:
=====================

01 - Base - Default
02 - Base - Color
03 - Base - Typhography
04 - Base - Animation

05 - Component - Button
06 - Component - Hero Slider
07 - Component - CMS
08 - Component - Banner
09 - Component - Product
10 - Component - Company Logo
11 - Component - Modal
12 - Component - Breadcrumb
13 - Component - Shop Sorting
14 - Component - Pagination
15 - Component - Sidebar Widget
16 - Component - Gallery
17 - Component - Tabstyle
18 - Component - Comment
19 - Component - Form

20 - Page - Shop
21 - Page - Product Single
22 - Page - Blog
23 - Page - Cart
24 - Page - Checkout
25 - Page - 404 
26 - Page - My Account
27 - Page - Login
28 - Page - Contact
29 - Page - FAQ
30 - Page - About

31 - Layout - Header
32 - Layout - Mobile Menu
33 - Layout - Vertical Menu
34 - Layout - Footer
35 - Layout - Custom Layout
36 - Layout - Table
37 - Layout - Offcanvas
38 - Layout - Addcart Offcanvas
39 - Layout - Slider Customize
40 - Layout - Utilities
******************************/
/*****************************
01 - Base - Default
*****************************/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&amp;display=swap");

* {
  margin: 0;
  padding: 0;
  list-style: none;
  word-wrap: break-word;
  outline: none;
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.hero .hero-slider img {
  height: 100% !important;
}



body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #181818;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

a {
  color: #181818;
  text-decoration: none;
}

i {
  font-size: 16px;
}

* button {
  border: none;
  background: transparent;
  outline: none !important;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.img-responsive {
  overflow: hidden;
}

.img-responsive img {
  width: 100%;
  height: auto;
}

.border-around {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.border-line--small {
  width: 20px;
  height: 1px;
  background: #fff;
  left: 0;
  top: 50%;
}

.list-space--small li {
  margin-bottom: 10px;
}

.list-space--small li:last-child {
  margin-bottom: 0;
}

.list-space--medium li {
  margin-bottom: 20px;
}

.list-space--medium li:last-child {
  margin-bottom: 0;
}

.list-space--large li {
  margin-bottom: 30px;
}

.list-space--large li:last-child {
  margin-bottom: 0;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.position-sticky {
  position: sticky;
  top: 0;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.float-margin-auto--15 {
  margin-left: -15px;
  margin-right: -15px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-grid-2 {
  width: 50%;
}

.float-grid-3 {
  width: 100%;
}

@media (min-width: 576px) {
  .float-grid-3 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .float-grid-3 {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .float-grid-3 {
    width: 33.3333%;
  }
}

.float-grid-4 {
  width: 100%;
}

@media (min-width: 576px) {
  .float-grid-4 {
    width: 50%;
  }

}

@media (min-width: 768px) {
  .float-grid-4 {
    width: 33.333%;
  }
}

@media (min-width: 992px) {
  .float-grid-4 {
    width: 25%;
  }

}

.float-grid-5 {
  width: 100%;
}

@media (min-width: 360px) {
  .float-grid-5 {
    width: 50%;
  }

}

@media (max-width: 375px) {
  .textone {
    font-size: 15px !important;
    margin-top: 0px !important;
  }

  .hero__content {
    top: 0 !important;
    left: center !important;
  }

  .texttwo {
    font-size: 10px !important;
    font-weight: 400 !important;
    /* width: 80%!important; */
    text-align: justify;
  }
}

@media (max-width: 426px) {
  .textone {
    font-size: 15px !important;
    /* margin-top: 0px!important; */
  }

  .hero__content {
    /* top: 0!important; */
    left: center !important;
  }

  .texttwo {
    font-size: 10px !important;
    font-weight: 400 !important;
    /* width: 80%!important; */
    /* text-align: justify; */
  }
}

@media (min-width: 1024px) {
  .textone {
    font-size: 30px !important;
    /* margin-top: 0px!important; */
  }

  .hero__content {
    /* top: 0!important; */
    left: center !important;
  }

  .texttwo {
    font-size: 20px !important;
    font-weight: 400 !important;
    /* width: 80%!important; */
    /* text-align: justify; */
  }
}

@media (min-width: 900px) {
  .textone {
    font-size: 40px !important;
    /* margin-top: 0px!important; */
  }

  .hero__content {
    /* top: 0!important; */
    left: center !important;
  }

  .texttwo {
    font-size: 22px !important;
    font-weight: 400 !important;
    /* width: 80%!important; */
    /* text-align: justify; */
  }
}

@media (min-width: 1200px) {
  .textone {
    font-size: 40px !important;
    /* margin-top: 0px!important; */
  }

  .hero__content {
    /* top: 0!important; */
    left: center !important;
  }

  .texttwo {
    font-size: 22px !important;
    font-weight: 400 !important;
    /* width: 80%!important; */
    /* text-align: justify; */
  }
}

@media (min-width: 1460px) {
  .textone {
    font-size: 50px !important;
    /* margin-top: 0px!important; */
  }

  .hero__content {
    /* top: 0!important; */
    left: center !important;
  }

  .texttwo {
    font-size: 24px !important;
    font-weight: 400 !important;
    /* width: 80%!important; */
    /* text-align: justify; */
  }
}

@media (min-width: 576px) {
  .float-grid-5 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .float-grid-5 {
    width: 33.333%;
  }
}

@media (min-width: 992px) {
  .float-grid-5 {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .float-grid-5 {
    width: 20%;
  }
}

.float-20-per {
  width: 20%;
}

.float-80-per {
  width: 80%;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-b-60-md {
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .m-b-60-md {
    margin-bottom: 0;
  }
}

.m-b-60-md .banner__box--single {
  text-align: center;
}

.m-t-60-md {
  margin-top: 60px;
}

@media (min-width: 992px) {
  .m-t-60-md {
    margin-top: 0;
  }
}

.m-t-60-md .banner__box--single {
  text-align: center;
}

.gray-bg.p-tb-100 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .gray-bg.p-tb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .gray-bg.p-tb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.gray-bg.m-t-100 {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .gray-bg.m-t-100 {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .gray-bg.m-t-100 {
    margin-top: 90px;
  }
}

.absolute-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.color-invert {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.tr-vertical-middle {
  vertical-align: middle;
}

/*****************************
02 - Base - Color
*****************************/
.gray-bg {
  background-color: #f5f5f5;
}

.pink-bg {
  background-color: #d1008a !important;
}

.white-color {
  color: #fff !important;
}

.white-orange {
  color: #ff4800 !important;
}

.black-color {
  color: #181818 !important;
}

.yellow-color {
  color: #fdd835 !important;
}

.yellow-bg {
  background-color: #fdd835 !important;
}

.white-bg {
  background: #fff !important;
}

/*****************************
03 - Base - Typhography
*****************************/
/*...::: Font Weight :::... */
.font--light {
  font-weight: 300;
}

.font--regular {
  font-weight: 400;
}

.font--semi-bold {
  font-weight: 600;
  text-align: justify;
}

.font--bold {
  font-weight: 700;
}

/*...::: Title - Font Style :::...*/
.title {
  display: block;
  line-height: 1;
  /*...::: Title - Font Size :::... */
}

.title--xlarge {
  font-size: 26px;
}

@media (min-width: 576px) {
  .title--xlarge {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .title--xlarge {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .title--xlarge {
    font-size: 53px;
  }
}

@media (min-width: 1200px) {
  .title--xlarge {
    font-size: 35px;
  }
}

@media (min-width: 1566px) {
  .title--xlarge {
    font-size: 60px;
  }
}

.title--large {
  font-size: 26px;

}

@media (min-width: 576px) {
  .title--large {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .title--large {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .title--large {
    font-size: 53px;
  }
}

@media (min-width: 1200px) {
  .title--large {
    font-size: 35px;
  }
}

@media (min-width: 1566px) {
  .title--large {
    font-size: 56px;
  }
}

.title--medium {
  font-size: 18px;
}

@media (min-width: 768px) {
  .title--medium {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .title--medium {
    font-size: 28px;
  }
}

@media (min-width: 1566px) {
  .title--medium {
    font-size: 30px;
  }
}

.title--normal {
  font-size: 18px;
}

@media (min-width: 768px) {
  .title--normal {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .title--normal {
    font-size: 28px;
  }
}

@media (min-width: 1566px) {
  .title--normal {
    font-size: 40px;
  }
}

.title--small {
  font-size: 13px;
}

@media (min-width: 576px) {
  .title--small {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .title--small {
    font-size: 17px;
  }
}

@media (min-width: 992px) {
  .title--small {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  .title--small {
    font-size: 11px;
  }

}

@media (min-width: 576px) {
  .title--large {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .title--large {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .title--large {
    font-size: 53px;
  }
}

@media (min-width: 1200px) {
  .title--large {
    font-size: 35px;
  }
}

@media (min-width: 1566px) {
  .title--large {
    font-size: 56px;
  }
}

.title--medium {
  font-size: 18px;
}

@media (min-width: 768px) {
  .title--medium {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .title--medium {
    font-size: 28px;
  }
}

@media (min-width: 1566px) {
  .title--medium {
    font-size: 30px;
  }
}

.title--normal {
  font-size: 18px;
}

@media (min-width: 768px) {
  .title--normal {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .title--normal {
    font-size: 28px;
  }
}

@media (min-width: 1566px) {
  .title--normal {
    font-size: 40px;
  }
}

.title--small {
  font-size: 13px;
}

@media (min-width: 576px) {
  .title--small {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .title--small {
    font-size: 17px;
  }
}

@media (min-width: 992px) {
  .title--small {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  .title--small {
    font-size: 11px;
  }
}

@media (min-width: 1566px) {
  .title--small {
    font-size: 18px;
  }
}

.title--tiny {
  font-size: 16px;
}

.title--description {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 36px;
}

@media (min-width: 768px) {
  .title--description {
    margin-top: 30px;
    margin-bottom: 46px;
  }
}

@media (min-width: 992px) {
  .title--description {
    margin-top: 30px;
    margin-bottom: 56px;
  }
}

@media (min-width: 1200px) {
  .title--description {
    margin-top: 28px;
    margin-bottom: 62px;
  }
}

.title--black {
  color: #181818;
}

.title--white {
  color: #fff;
}

.section-content__title {
  font-size: 24px;
  line-height: 22px;
  color: #181818;
  font-weight: 700;
  text-transform: uppercase;
}

.offcanvas-title {
  font-size: 22px;
  line-height: 22px;
  color: #181818;
  font-weight: 700;
  text-align: justify;
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .section-content__title {
    font-size: 35px;
    padding: 10px 0px;
  }
}

.section-content__desc {
  margin-top: 5px;
  color: #888;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.section-content__desc:hover {
  color: #e81212;
}

.section-content--border {
  padding-bottom: 28px;
  border-bottom: 1px solid #ddd;
}

@media (min-width: 992px) {
  .section-content--border {
    padding-bottom: 38px;
  }
}

.block-quote {
  margin-left: 80px;
  color: #666;
  background: #eef0f1;
  border-left: 5px solid #e81212;
  padding: 15px 30px;
  margin: 30px 0 30px 80px;
  text-align: justify;
  font-weight: 600;
  font-style: italic;
  line-height: 1.8;
}

.carousel-indicators>carousel-inner>carousel-control-prev {
  position: absolute;
  top: 40%;
  bottom: 40%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #000000;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

.card-text {
  text-align: justify;
}

.para__text {
  margin: 10px 0px;
  text-align: justify;
}

.para__text:last-child {
  margin: 20px 0 0 0;
}

.text--gray {
  color: #666;
}

.text-area {
  margin-bottom: 60px;
}

.text-area:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .text-area {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .text-area {
    margin-bottom: 100px;
  }
}

.text-area h5 {
  margin-bottom: 30px;
}

/*****************************
04 - Base - Animation
*****************************/
@-webkit-keyframes animate-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes animate-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes animate-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes animate-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes animate-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes animate-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes animate-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes animate-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes animate-zoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes animate-zoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes animate-zoom-blink {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  25% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes animate-zoom-blink {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  25% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.glossy-flash-animate::before {
  background: rgba(255, 255, 255, 0.3);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 50%;
  top: 0;
  opacity: 1;
}

.glossy-flash-animate::after {
  background: rgba(255, 255, 255, 0.6);
  bottom: 50%;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  opacity: 1;
}

.glossy-flash-animate:hover::before {
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 900ms linear;
  transition: all 900ms linear;
}

.glossy-flash-animate:hover::after {
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 900ms linear;
  transition: all 900ms linear;
}

/*****************************
05 - Component - Button
*****************************/
.btn {
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  outline: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 0;
  padding: 0;
}

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn--box {
  display: inline-block;
}

.btn--block {
  display: block;
}

.btn--radius {
  border-radius: 30px;
}

.btn--border-white {
  color: #181818;
  border: 1px solid #e0e0e0;
}

.btn--border-white:first-child:hover,
.btn--border-white :not(.btn-check)+.btn:hover {
  color: #fff;
  background: #e81212;
  border-color: #e81212;
}

.btn--border-blue {
  color: #0063d1;
  border: 2px solid #0063d1;
}

.btn--border-blue:first-child:hover,
.btn--border-blue :not(.btn-check)+.btn:hover {
  background: #0063d1;
  border-color: #0063d1;
  color: #fff;
}

.btn--submit {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.btn--search {
  right: 0;
  width: 60px;
  text-align: center;
  height: auto;
}

.btn--underline {
  padding-bottom: 3px;
  border-bottom: 1px solid;
}

.btn--underline-white {
  color: #fff;
}

.btn--underline-white:first-child:hover,
.btn--underline-white :not(.btn-check)+.btn:hover {
  color: #fb6a21;
}

.btn--underline-black {
  color: #181818;
}

.btn--underline-black:first-child:hover,
.btn--underline-black :not(.btn-check)+.btn:hover {
  color: #fb6a21;
}

.btn--large {
  padding: 15px 20px;
}

@media (min-width: 768px) {
  .btn--large {
    padding: 16px 25px;
  }
}

@media (min-width: 992px) {
  .btn--large {
    padding: 17px 35px;
  }
}

@media (min-width: 1200px) {
  .btn--large {
    padding: 18px 40px;
  }
}

.btn--medium {
  padding: 13px 40px;
}

.btn--small {
  padding: 12px 20px;
}

@media (min-width: 768px) {
  .btn--small {
    padding: 15px 35px;
  }
}

.btn--tiny {
  padding: 15px 20px;
}

.btn--blue {
  background: #0063d1;
  color: #fff;
}

.btn--blue:first-child:hover,
.btn--blue :not(.btn-check)+.btn:hover {
  background: #181818;
  color: #fff;
}

.btn--white {
  background: #ffffff;
  color: #000;
}

.btn--white-hover-black:first-child:hover,
.btn--white-hover-black :not(.btn-check)+.btn:hover {
  background: #000;
  color: #fff;
}

.btn--white-hover-red:first-child:hover,
.btn--white-hover-red :not(.btn-check)+.btn:hover {
  background: #e81212;
  color: #fff;
}

.btn--white-hover-orange:first-child:hover,
.btn--white-hover-orange :not(.btn-check)+.btn:hover {
  background: #fb6a21;
  color: #fff;
}

.btn--black {
  background: #181818;
  color: #fff;
}

.btn--black-hover-white:first-child:hover,
.btn--black-hover-white :not(.btn-check)+.btn:hover {
  background: #fff;
  color: #181818;
}

.btn--black-hover-red:first-child:hover,
.btn--black-hover-red :not(.btn-check)+.btn:hover {
  background: #fb6a21;
  color: #fff;
}

.btn--black-hover-orange:first-child:hover,
.btn--black-hover-orange :not(.btn-check)+.btn:hover {
  background: #fb6a21;
  color: #fff;
}

.btn--black-hover-golden:first-child:hover,
.btn--black-hover-golden :not(.btn-check)+.btn:hover {
  background: #b09e64;
  color: #fff;
}

.btn--gray {
  background: #e0e0e0;
  color: #000;
}

.btn--gray-hover-red:first-child:hover,
.btn--gray-hover-red :not(.btn-check)+.btn:hover {
  background: #e84f12;
  color: #fff;
}

.btn--gray-hover-orange:first-child:hover,
.btn--gray-hover-orange :not(.btn-check)+.btn:hover {
  background: #fb6a21;
  color: #fff;
}

.btn--gray-hover-golden:first-child:hover,
.btn--gray-hover-golden :not(.btn-check)+.btn:hover {
  background: #b09e64;
  color: #fff;
}

.btn--red {
  background: #fb6a21;
  color: #fff;
}

.btn--red-hover-red:first-child:hover,
.btn--red-hover-red :not(.btn-check)+.btn:hover {
  background: #fb6a21;
  color: #fff;
}

.btn--red-hover-black:first-child:hover,
.btn--red-hover-black :not(.btn-check)+.btn:hover {
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
}

.btn--orange {
  background: #fb6a21;
  color: #fff;
}

.btn--orange-hover-orange:first-child:hover,
.btn--orange-hover-orange :not(.btn-check)+.btn:hover {
  background: #fb6a21;
  color: #fff;
}

.btn--golden {
  background: #b09e64;
  color: #fff;
}

.btn--golden-hover-golden:first-child:hover,
.btn--golden-hover-golden :not(.btn-check)+.btn:hover {
  background: rgba(176, 158, 100, 0.9);
  color: #fff;
}

.btn--uppercase {
  text-transform: uppercase;
}

.btn--hover-zoom {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

button.btn--block {
  width: 100%;
}

.link--gray {
  color: #666;
  text-decoration: none !important;
  font-weight: 600;
}

.link--gray:first-child:hover,
.link--gray :not(.btn-check)+.btn:hover {
  color: #e81212;
}

.link--icon-left {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.link--icon-left i {
  margin-right: 10px;
}

.link--icon-right {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.link--icon-right i {
  margin-left: 10px;
}

.nametag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nametag span {
  font-weight: 700;
}

.nametag__list li {
  display: inline-block;
  margin-right: 10px;
}

.nametag__list a {
  color: #888;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nametag__list a:first-child:hover,
.nametag__list a :not(.btn-check)+.btn:hover {
  color: #e81212;
}

.internal-link {
  color: #fff;
  margin-left: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.internal-link:first-child:hover,
.internal-link :not(.btn-check)+.btn:hover {
  color: #f17b08;
}

/*****************************
06 - Component - Hero Slider
*****************************/
.hero {
  position: relative;
  overflow: hidden;
}

.hero .hero-slider {
  position: relative;
}

.hero .hero-slider img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 420px;
}

@media (min-width: 576px) {
  .hero .hero-slider img {
    height: 425px;
  }
}

@media (min-width: 768px) {
  .hero .hero-slider img {
    height: 550px;
  }
}

@media (min-width: 992px) {
  .hero .hero-slider img {
    height: 635px;
  }
}

@media (min-width: 1200px) {
  .hero .hero-slider img {
    height: 90vh;
  }
}

.hero__content {
  position: absolute;
  top: 30%;
  left: 0%;
  width: 100%;
  /* -webkit-transform: translateY(-50%); */
  /* transform: translateY(50%); */
  transform: translateX(-24%);
}

.hero__content .title--small.pos-relative {
  padding-left: 30px;
  /* margin-top: 20px; */
}

.title--small {
  line-height: 14px;
}

@media (min-width: 768px) {
  .hero__content .title--small.pos-relative {
    margin-top: 20px;
  }
}


.hero__content .btn {
  margin-top: 0px;
}

@media (min-width: 768px) {
  .hero__content .btn {
    margin-top: 30px;
  }
}

.hero .slick-active .hero__content--inner .hero-title-1 {
  -webkit-animation: animate-top 0.5s linear 0.2s backwards;
  animation: animate-top 0.5s linear 0.2s backwards;
}

.hero .slick-active .hero__content--inner .hero-title-2 {
  -webkit-animation: animate-top 0.5s linear 0.8s backwards;
  animation: animate-top 0.5s linear 0.8s backwards;
}

.hero .slick-active .hero__content--inner .hero-title-3 {
  -webkit-animation: animate-top 0.6s linear 1.5s backwards;
  animation: animate-top 0.6s linear 1.5s backwards;
}

.hero .slick-active .hero__content--inner .hero-btn-link {
  -webkit-animation: animate-bottom 0.8s linear 2s backwards;
  animation: animate-bottom 0.8s linear 2s backwards;
}

/*****************************
07 - Component - CMS
*****************************/
.cms.m-t-100 {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .cms.m-t-100 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .cms.m-t-100 {
    margin-top: 100px;
  }
}

.cms.p-t-100 {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .cms.p-t-100 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .cms.p-t-100 {
    padding-top: 100px;
  }
}

.cms .col-12 {
  margin-bottom: 40px;
}

.cms .col-12:last-child {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .cms .col-12:nth-child(3) {
    margin-bottom: 0;
  }

  .cms .col-12:nth-child(4) {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .cms .col-12:nth-child(3) {
    margin-bottom: 0;
  }

  .cms .col-12:nth-child(4) {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .cms .col-12 {
    margin-bottom: 0px;
  }
}

.cms__content {
  text-align: center;
}

.cms__icon-img {
  width: 77px;
  overflow: hidden;
}

.cms__title {
  color: #181818;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 15px;
}

.cms__des {
  line-height: 23px;
  color: #666;
  margin: 0;
}

/*****************************
08 - Component - Banner
*****************************/
.banner.m-t-100 {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .banner.m-t-100 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .banner.m-t-100 {
    margin-top: 100px;
  }
}

.banner--2 {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .banner--2 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .banner--2 {
    margin-top: 0;
  }
}

.banner--2 .row .col-12 {
  margin-top: 30px;
}

.banner--2 .row .col-12:first-child {
  margin-top: 0;
}

.banner .col-12>.banner__box {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .banner .col-12>.banner__box {
    margin-bottom: 0px;
  }
}

.banner .col-12:last-child>.banner__box {
  margin-bottom: 0;
}

.banner__box {
  max-width: 100%;
  overflow: hidden;
}

.banner__box--single {
  margin-left: auto;
  margin-right: auto;
}

.banner__box--single-text-style-1 .btn {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.banner__link {
  display: block;
}

.banner__img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.banner__img--size-xl {
  width: 100%;
  height: 320px;
}

@media (min-width: 576px) {
  .banner__img--size-xl {
    height: 320px;
  }
}

@media (min-width: 768px) {
  .banner__img--size-xl {
    height: 340px;
  }
}

@media (min-width: 992px) {
  .banner__img--size-xl {
    height: 360px;
  }
}

@media (min-width: 1200px) {
  .banner__img--size-xl {
    height: auto;
  }
}

.banner--btn-1 {
  top: 220px;
}

@media (min-width: 576px) {
  .banner--btn-1 {
    top: 200px;
  }
}

@media (min-width: 768px) {
  .banner--btn-1 {
    top: 200px;
  }
}

@media (min-width: 992px) {
  .banner--btn-1 {
    top: 204px;
  }
}

@media (min-width: 1200px) {
  .banner--btn-1 {
    top: 295px;
  }
}

@media (min-width: 1566px) {
  .banner--btn-1 {
    top: 235px;
  }
}

.banner--btn-2 {
  left: 20px;
  bottom: 20px;
}

@media (min-width: 1200px) {
  .banner--btn-2 {
    left: 65px;
  }
}

@media (min-width: 576px) {
  .banner--btn-2 {
    bottom: 47px;
  }
}

@media (min-width: 768px) {
  .banner--btn-2 {
    bottom: 88px;
  }
}

@media (min-width: 992px) {
  .banner--btn-2 {
    bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .banner--btn-2 {
    bottom: 40px;
  }
}

@media (min-width: 1566px) {
  .banner--btn-2 {
    bottom: 75px;
  }
}

.banner--btn-3 {
  bottom: 50px;
}

@media (min-width: 576px) {
  .banner--btn-3 {
    bottom: 60px;
  }
}

@media (min-width: 768px) {
  .banner--btn-3 {
    bottom: 55px;
  }
}

@media (min-width: 992px) {
  .banner--btn-3 {
    bottom: 72px;
  }
}

@media (min-width: 1200px) {
  .banner--btn-3 {
    bottom: 27px;
  }
}

@media (min-width: 1566px) {
  .banner--btn-3 {
    bottom: 80px;
  }
}

.banner--btn-4 {
  top: 170px;
}

@media (min-width: 576px) {
  .banner--btn-4 {
    top: 187px;
  }
}

@media (min-width: 768px) {
  .banner--btn-4 {
    top: 187px;
  }
}

@media (min-width: 992px) {
  .banner--btn-4 {
    top: 193px;
  }
}

@media (min-width: 1200px) {
  .banner--btn-4 {
    top: 220px;
  }
}

@media (min-width: 1566px) {
  .banner--btn-4 {
    top: 220px;
  }
}

.banner--btn-5 {
  left: 20px;
  bottom: 100px;
}

@media (min-width: 1200px) {
  .banner--btn-5 {
    left: 65px;
  }
}

@media (min-width: 576px) {
  .banner--btn-5 {
    bottom: 80px;
  }
}

@media (min-width: 768px) {
  .banner--btn-5 {
    bottom: 95px;
  }
}

@media (min-width: 992px) {
  .banner--btn-5 {
    bottom: 105px;
  }
}

@media (min-width: 1200px) {
  .banner--btn-5 {
    bottom: 195px;
  }
}

@media (min-width: 1566px) {
  .banner--btn-5 {
    bottom: 324px;
  }
}

.banner--btn-6 {
  left: 20px;
  bottom: 50px;
}

@media (min-width: 1200px) {
  .banner--btn-6 {
    left: 65px;
  }
}

@media (min-width: 576px) {
  .banner--btn-6 {
    bottom: 60px;
  }
}

@media (min-width: 768px) {
  .banner--btn-6 {
    bottom: 55px;
  }
}

@media (min-width: 992px) {
  .banner--btn-6 {
    bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .banner--btn-6 {
    bottom: 20px;
  }
}

@media (min-width: 1566px) {
  .banner--btn-6 {
    bottom: 40px;
  }
}

@media (min-width: 1566px) {
  .banner__content {
    top: 40px;
  }
}

.banner__content {
  padding: 0 20px;
}

@media (min-width: 1200px) {
  .banner__content {
    padding: 0 60px;
  }
}

.banner__content .btn {
  z-index: 9;
}

.banner__content--top {
  width: 100%;
  top: 70px;
}

.banner__content--center {
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.banner__title {
  line-height: 1;
}

.banner__title--small {
  font-size: 16.666px;
}

@media (min-width: 992px) {
  .banner__title--small {
    font-size: 20px;

  }
}

@media (min-width: 1200px) {
  .banner__title--small {
    font-size: 30px;
    top: 0px;
  }
}

.banner__title--medium {
  font-size: 22px;

}

@media (min-width: 992px) {
  .banner__title--medium {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  .banner__title--medium {
    font-size: 40px;
  }
}

.banner__title--large {
  font-size: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
}

@media (min-width: 320px) {
  .banner__title--large {
    align-items: center;
    font-size: 1.3em;
    margin-top: -70px;
  }
}

@media (min-width: 425px) {
  .banner__title--large {
    align-items: center;
    font-size: 2em;
    margin-top: -40px;
  }
}

@media (min-width: 500px) {
  .banner__title--large {
    align-items: center;
    font-size: 2.5em;
    margin-top: -30px;
  }
}

@media (min-width: 700px) {
  .banner__title--large {
    align-items: center;
    font-size: 3em;
    /* margin-top: 60px; */
  }
}

@media (min-width: 992px) {
  .banner__title--large {
    align-items: center;
    font-size: 4em;
    /* margin-top: 60px; */
  }
}

@media (min-width: 1200px) {
  .banner__title--large {
    font-size: 4em;
    align-items: center;
    margin-top: 6px;
  }
}

@media (min-width: 1400px) {
  .banner__title--large {
    font-size: 5em;
    align-items: center;
    margin-top: 10px;
  }
}


/*****************************
09 - Component - Product
*****************************/
.product.m-t-100 {
  margin-top: 57px;
}

@media (min-width: 768px) {
  .product.m-t-100 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .product.m-t-100 {
    margin-top: 100px;
  }
}

.product-type--segment .col-12 {
  margin-bottom: 28px;
}

.product-type--segment .col-12:last-child {
  margin-bottom: 0px;
}

@media (min-width: 992px) {
  .product-type--segment .col-12 {
    margin-bottom: 0;
  }
}

.product__img-box {
  overflow: hidden;
  position: relative;
}

.product__img--link .product__img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.product__img--link:hover .product__img:nth-child(2) {
  opacity: 1;
}

.product__img {
  width: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (min-width: 320px) {
  .nav-header {
    padding: 5px 0 10px 0px;
  }
}

@media (min-width: 375px) {
  .nav-header {
    padding: 5px 0 20px 0px;
  }
}

@media (min-width: 425px) {
  .nav-header {
    padding: 5px 0 30px 0px;
  }
}

@media (min-width: 768px) {
  .nav-header {
    padding: 5px 0 80px 0px;
  }
}

@media (min-width: 1024px) {
  .nav-header {
    padding: 5px 0 70px 0px;
  }
}

.product__img-nav--right {
  top: 10px;
  right: 0px;
  background: #fff;
  padding: 10px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}

.product__img-nav--right li {
  margin-bottom: 5px;
}

.product__img-nav--right li a {
  display: inline-block;
  text-align: center;
  color: #181818;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product__img-nav--right li a:hover {
  color: #e81212;
}

.product__img-box:hover .product__img-nav--right {
  right: 10px;
  opacity: 1;
  visibility: visible;
}

.product__tag {
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: center;
  padding: 1px 8px;
  color: #fff;
  font-size: 12px;
}

.product__tag--sale-red {
  background: #e81212;
}

.product__tag--sale-orange {
  background: #f17b08;
}

.product__tag--sale-golden {
  background: #b09e64;
}

.product__tag--new {
  background: #0063d1;
}

.product__tag--discount {
  background: #62ab00;
}

.product__content--catagory {
  width: 100%;
  bottom: 20px;
}

.product__content--bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  left: 0;
  bottom: -5px;
  padding: 2.5px 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.product__content--bottom-white {
  background: #fff;
}

.product__content--bottom-gray {
  background: #f5f5f5;
}

.product__content--bottom li a {
  display: inline-block;
  text-align: center;
  color: #181818;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 13px;
}

.product__content--bottom li a:hover {
  color: #e81212;
}

.product__content--bottom li a i {
  padding-right: 10px;
}

.product__price-del {
  font-size: 14px;
  color: #a4a4a4;
  font-weight: 400;
  text-decoration: line-through;
  margin-right: 5px;
}

.product__price-reg {
  font-size: 16px;
  font-weight: 600;
  color: #181818;
}

.product__link {
  display: block;
  overflow: hidden;
  color: #181818;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.product__link:hover {
  color: #e81212;
}

.product__desc {
  font-size: 14px;
  color: #777777;
  line-height: 24px;
}

.product__review {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product__review i {
  font-size: 20px;
}

.product__review li {
  margin-right: 2px;
}

.product__review li:last-child {
  margin-right: 0px;
}

.product__review--fill {
  color: #fdd835;
}

.product__review--blank {
  color: #888;
}

.product__box--catagory {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.product__box--catagory:last-child {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .product__box--catagory {
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 30px;
  }

  .product__box--catagory:last-child {
    margin-right: 0px;
  }

  .product__box--catagory:nth-child(3),
  .product__box--catagory:nth-child(4) {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .product__box--catagory {
    width: calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 30px;
  }

  .product__box--catagory:last-child {
    margin-right: 0px;
  }

  .product__box--catagory:nth-child(3),
  .product__box--catagory:nth-child(4) {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .product__box--catagory {
    width: calc(25% - 30px);
    margin-right: 30px;
    margin-bottom: 0;
  }

  .product__box--catagory:last-child {
    margin-right: 0px;
  }
}

.product__box--segment .product__img-box {
  width: 27%;
  margin-right: 20px;
}

.product__box--border-hover {
  border: 2px solid #888;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.product__box--border-hover:hover {
  border-color: #fb6a21;
}

.product__box:hover .product__content--bottom {
  bottom: 0;
  opacity: 1;
  visibility: visible;
  font-size: 14px;
}

/*****************************
10 - Component - Company Logo
*****************************/
.company-logo__area .slick-list {
  margin-left: -100px;
}

.company-logo__area .slick-slide {
  margin-left: 100px;
}

.company-logo__item {
  text-align: center;
}

.company-logo__link {
  display: block;
}

.company-logo__img {
  padding: 10px;
  max-width: 100%;
  height: auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.company-logo__img:hover {
  opacity: 1;
}

/*****************************
11 - Component - Modal
*****************************/
.modal {
  z-index: 99999;
  background: rgba(24, 24, 24, 0.5);
  padding-right: 0 !important;
}

.modal-product-image--large {
  margin-bottom: 20px;
}

.modal-dialog {
  max-width: calc(100% - 20px) !important;
  margin: 0 auto !important;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 730px !important;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 870px !important;
  }
}

@media (min-width: 1200px) {
  .modal-dialog {
    min-width: 825px !important;
  }
}

.modal-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px;
  background: #546583;
  color: #fff;
}

.modal-header .close {
  margin: 0 !important;
  color: #fff;
}

.modal-title {
  text-align: center;
  margin: 0 auto;
}

.modal__border {
  border-left: none;
}

@media (min-width: 992px) {
  .modal__border {
    border-left: 1px solid #ddd;
  }
}

.modal__product-img {
  text-align: center;
}

.modal__product-title {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .modal__product-title {
    margin-top: 0px;
  }
}

.modal__product-title,
.modal__product-price {
  display: block;
  font-weight: 500;
}

.modal__product-info,
.modal__product-shipping-info {
  font-weight: 500;
}

.modal__product-info span,
.modal__product-shipping-info span {
  font-weight: 300;
  padding-left: 5px;
}

.modal__product-cart-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.modal__product-cart-buttons a {
  margin-bottom: 15px;
}

.modal__product-cart-buttons a:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .modal__product-cart-buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .modal__product-cart-buttons a {
    margin-bottom: 0;
    margin-right: 30px;
  }

  .modal__product-cart-buttons a:last-child {
    margin-right: 0;
  }
}

/*****************************
12 - Component - Breadcrumb
*****************************/
.page-breadcrumb {
  background: #546583;
  text-align: center;
  padding: 90px 0 90px 0px;
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .page-breadcrumb {
    padding: 140px 0 125px 0px;
  }
}

@media (min-width: 1200px) {
  .page-breadcrumb {
    padding: 185px 0 130px 0px;
  }
}

@media (min-width: 768px) {
  .page-breadcrumb {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .page-breadcrumb {
    margin-bottom: 100px;
  }
}

.page-breadcrumb__menu {
  font-size: 16px;
  color: #181818;
}

.page-breadcrumb__nav {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  color: #fff;
}

.page-breadcrumb__nav::after {
  content: "\f054";
  padding-left: 10px;
  font-family: "Font Awesome 5 Free";
}

.page-breadcrumb__nav:last-child::after {
  content: "";
}

.page-breadcrumb__nav a {
  color: #fff;
  text-decoration: none;
}

.page-breadcrumb__nav a:hover {
  color: #fb6a21;
}

.page-breadcrumb__nav.active {
  color: #e1e1e1;
}

/*****************************
13 - Component - Shop Sorting
*****************************/
.sort-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 13px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 768px) {
  .sort-box {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.sort-box__left,
.sort-box__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sort-box__left span,
.sort-box__right span {
  display: inline-block;
}

.sort-box__left {
  margin-bottom: 20px;
}

.sort-box__left span {
  margin-left: 55px;
}

@media (min-width: 768px) {
  .sort-box__left {
    margin-bottom: 0;
  }
}

.sort-box__right span {
  margin-right: 20px;
}

.sort-box__tab-list li {
  margin-right: 15px;
  display: inline-block;
}

.sort-box__tab-list li:last-child {
  margin-right: 0;
}

.sort-nav-link {
  display: block;
  font-size: 18px;
  text-decoration: none !important;
  color: #181818;
  background: transparent;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sort-nav-link:hover,
.sort-nav-link.active {
  color: #fff;
  background: #fb6a21;
}

.select-sort {
  padding: 5px 100px 5px 15px;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-sort option {
  text-align: right;
  border: 1px solid #777;
  padding: 5px 15px;
  margin: 5px 0;
}

.select-sort__arrow {
  position: relative;
  margin-bottom: 0;
}

.select-sort__arrow::after {
  content: "\f0d7";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 25px;
}

.shop-grid .product__box--default {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.shop-grid.active .product__box--default {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.shop-list>.product__box--list {
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.shop-list.active>.product__box--list {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.product__box--list .product__img {
  margin: 0 auto;
  text-align: center;
  display: block;
}

/*****************************
14 - Component - Pagination
*****************************/
.page-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .page-pagination {
    margin-top: 80px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .page-pagination {
    margin-top: 100px;
  }
}

.page-pagination__list {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .page-pagination__list {
    margin-top: 0;
  }
}

.page-pagination__item {
  display: inline-block;
  margin-right: 5px;
}

.page-pagination__item:last-child {
  margin-right: 0;
}

.page-pagination__link {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #e0e0e0;
}

.page-pagination__link:hover,
.page-pagination__link.active {
  border: 1px solid #e81212;
  background: #e81212;
  color: #fff;
}

/*****************************
15 - Component - Sidebar Widget
*****************************/
.sidebar {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .sidebar {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .sidebar {
    margin-top: 0px;
  }
}

.sidebar__widget {
  margin-bottom: 40px;
}

.sidebar__widget:last-child {
  margin-bottom: 0;
}

.sidebar__box {
  position: relative;
}

.sidebar__box::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #e81212;
  bottom: -19.5px;
  left: 0;
}

.sidebar__box::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  bottom: -20px;
  left: 0;
}

.sidebar__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.sidebar__menu>li {
  margin-bottom: 10px;
}

.sidebar__menu>li:last-child {
  margin-bottom: 0;
}

.sidebar__menu-collapse-list:last-child .accordion__title {
  margin-bottom: 0;
}

.sidebar__post-img {
  width: 40%;
}

.sidebar__post-content {
  width: 60%;
  margin-left: 10px;
}

.sidebar__post-content a {
  font-size: 14px;
  word-wrap: break-word;
  font-weight: 500;
}

.sidebar__post-content span {
  font-size: 13px;
}

.sidebar__tag li {
  display: inline-block;
  margin-right: 5px;
}

.sidebar__menu-filter-list {
  margin-bottom: 10px;
}

.sidebar__menu-filter-list:last-child {
  margin-bottom: 0;
}

.accordion__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.accordion__category-list {
  border-top: 1px solid #e0e0e0;
}

.accordion__category-list li {
  padding-left: 5px;
  padding: 5px;
  display: block;
}

.accordion a {
  text-decoration: none !important;
  color: #181818;
}

.accordion a:hover {
  color: #e81212;
}

/*****************************
16 - Component - Gallery
*****************************/
.product-gallery-box {
  padding: 0 20px;
}

.product-gallery-box__vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-gallery-box__tab-left {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.product-gallery-box .swiper-slide {
  height: 100% !important;
}

.product-gallery-box--gallery {
  float: left;
}

.product-gallery-box--gallery img {
  width: 50%;
}

.gallery--right {
  width: 80%;
}

.gallery--left {
  width: 20%;
}

.product-image--large .img-fluid {
  max-width: 100% !important;
}

.product-image--gallery .img-responsive {
  margin: 10px;
}

/*set a border on the images to prevent shifting*/
#gallery-zoom img {
  border: 2px solid #777;
}

/*Change the colour*/
.zoom-active img {
  border: 2px solid #333 !important;
}

.modal-product-image--thumb-horizontal,
.product-image--thumb-horizontal {
  margin-top: 20px;
}

.modal-product-image--thumb-horizontal .slick-list,
.product-image--thumb-horizontal .slick-list {
  margin-right: -10px;
}

.modal-product-image--thumb-horizontal .slick-slide,
.product-image--thumb-horizontal .slick-slide {
  margin-right: 10px;
}

.modal-product-image--thumb-vertical .slick-list,
.product-image--thumb-vertical .slick-list {
  margin-bottom: -10px;
}

.modal-product-image--thumb-vertical .slick-slide,
.product-image--thumb-vertical .slick-slide {
  margin-bottom: 10px;
}

.modal-product-image--thumb img,
.product-image--thumb img {
  cursor: pointer;
  border: 1px solid rgba(84, 101, 131, 0.2) !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.modal-product-image--thumb img:hover,
.product-image--thumb img:hover {
  border-color: #e81212 !important;
}

.text-reference {
  font-size: 14px;
  color: #666;
}

.product__stock {
  font-weight: 700;
  color: #777;
}

.product__stock i {
  color: #62ab00;
}

.product-var {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.product-var__item {
  margin-bottom: 30px;
}

.product-var__text {
  display: block;
  margin-bottom: 9px;
  font-weight: 500;
}

.product-social span {
  color: #777;
  margin-bottom: 20px;
  display: inline-block;
  font-size: 16px;
}

.product-social-link li {
  display: inline-block;
  margin-right: 10px;
}

.product-social-link li:last-child {
  margin-right: 0;
}

.product-social-link li:nth-child(1) a:hover {
  background: #3c5b9b;
}

.product-social-link li:nth-child(2) a:hover {
  background: #40c1df;
}

.product-social-link li:nth-child(3) a:hover {
  background: #eb8314;
}

.product-social-link li:nth-child(4) a:hover {
  background: #ed3c32;
}

.product-social-link a {
  color: #777;
  width: 40px;
  height: 40px;
  display: block;
  border: 1px solid #e0e0e0;
  text-align: center;
  line-height: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-social-link a:hover {
  color: #fff;
  border: 1px solid transparent;
}

/*****************************
17 - Component - Tabstyle
*****************************/
.tablist li {
  position: relative;
}

.tablist--style-black .nav-link {
  color: #888;
  padding: 0 0 26px 0;
  margin-bottom: 20px;
  position: relative;
}

@media (min-width: 768px) {
  .tablist--style-black .nav-link {
    margin-bottom: 0px;
  }
}

.tablist--style-black .nav-link::after {
  position: absolute;
  content: "";
  bottom: 10px;
  left: 50%;
  right: 50%;
  width: 0%;
  height: 2px;
  background: #e81212;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .tablist--style-black .nav-link::after {
    bottom: 0px;
  }
}

.tablist--style-black .nav-link.active,
.tablist--style-black .nav-link:hover {
  color: #000;
}

.tablist--style-black .nav-link.active::after,
.tablist--style-black .nav-link:hover::after {
  left: 0%;
  right: 0%;
  width: 100%;
}

.tablist--style-blue .nav-link {
  color: #888;
}

.tablist--style-blue .nav-link.active,
.tablist--style-blue .nav-link:hover {
  color: #0063d1;
}

.tablist--style-title li>.nav-link {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.tablist--style-seperator li::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 15px;
  background: #777;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: calc(100% - 35px);
}

.tablist--style-seperator li:last-child::after {
  display: none;
}

.tablist--style-gap li:last-child>.nav-link {
  margin-right: 0;
}

.tablist--style-gap-20 li>.nav-link {
  margin-right: 20px;
}

.tablist--style-gap-30 li>.nav-link {
  margin-right: 30px;
}

.tablist--style-gap-70 li>.nav-link {
  margin-right: 70px;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

.tab-animate-zoom .tab-pane {
  -webkit-transform: translateY(-100px) scale(0.5);
  transform: translateY(-100px) scale(0.5);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.tab-animate-zoom .tab-pane.active {
  -webkit-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
}

/*****************************
18 - Component - Comment
*****************************/
.comment {
  padding: 0;
}

@media (min-width: 768px) {
  .comment {
    padding: 0px 20px 20px 20px;
  }
}

.comment__list {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 20px;
  padding-bottom: 15px;
}

.comment__list:last-child {
  border-bottom: none;
}

.comment__list:first-child {
  padding-top: 0;
}

.comment__list>.comment__reply {
  margin-left: 0;
}

@media (min-width: 768px) {
  .comment__list>.comment__reply {
    margin-left: 100px;
  }
}

.comment__reply li {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  margin-top: 15px;
}

.comment__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 576px) {
  .comment__wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.comment__img {
  margin-right: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.comment__img img {
  width: 78px;
  height: 78px;
  border-radius: 5px;
}

.comment__name {
  font-weight: 600;
  margin-right: 10px;
}

.comment__content-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*****************************
19 - Component - Form
*****************************/
.form-box__single-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 25px;
}

.form-box__single-group label {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.form-box__single-group input,
.form-box__single-group textarea,
.form-box__single-group select {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 10px 20px;
  width: 100%;
  outline: none;
  font-size: 14px;
}

select {
  outline: none;
  border: 1px solid #777;
  padding: 5px 15px;
}

label>span {
  display: inline-block;
  padding-left: 30px;
  font-weight: 600;
}

.product-color-select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.product-color-select::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
}

.product-color-select:checked::after {
  content: "\f00c" !important;
  color: #fff;
}

.product-color-select[value=color-red]::after {
  background: #ed3c32 !important;
}

.product-color-select[value=color-green]::after {
  background: #62ab00 !important;
}

.product-color-select[value=color-blue]::after {
  background: #0063d1 !important;
}

input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

input[type=checkbox]::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #e0e0e0;
  top: -15px;
  line-height: 20px;
  background: #fff;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

input[type=checkbox]:checked::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Light";
  color: #fff;
  background: #e81212;
}

input[type=radio].shipping-select::after {
  position: absolute;
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  background: #fff;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: -20px;
  color: #e81212;
  font-size: 20px;
}

input[type=radio].shipping-select:checked::after {
  content: "\f058";
}

input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

input[type=radio]::after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Free";
  background: #fff;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: -17px;
  color: #0063d1;
}

input[type=radio]:checked::after {
  content: "\f058";
}

.from-box__buttons {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

@media (min-width: 576px) {
  .from-box__buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.form-box-right {
  margin-top: 25px;
}

@media (min-width: 576px) {
  .form-box-right {
    margin-top: 0;
  }
}

.password__toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.password__toggle--btn {
  margin-left: -25px;
}

/*****************************
20 - Page - Shop
*****************************/
.shop__list-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .shop__list-link {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.shop__list-link li a {
  display: block;
}

.shop-grid .product__box--default {
  margin-top: 40px;
}

.shop-list>.product__box--list {
  margin-top: 40px;
}

.border-right.pos-absolute {
  border-right: none !important;
}

@media (min-width: 768px) {
  .border-right.pos-absolute {
    border-right: 1px solid #dee2e6 !important;
    height: 100%;
    right: 0;
  }
}

/*****************************
21 - Page - Product Single
*****************************/
.product-details-tab-box {
  border: 1px solid #e0e0e0;
  padding: 20px 10px;
}

@media (min-width: 768px) {
  .product-details-tab-box {
    /* padding: 30px 40px; */
  }
}

.review-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.review-box>* {
  margin-right: 30px;
}

.review-box>*:last-child {
  margin-right: 0;
}

.product-dis__img {
  display: inline-block;
  border: 1px solid #777;
}

.product-dis__title {
  font-weight: 500;
}

.product-dis__text {
  font-weight: 300;
}

/*****************************
22 - Page - Blog
*****************************/
.blog.m-t-100 {
  margin-top: 57px;
}

@media (min-width: 768px) {
  .blog.m-t-100 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .blog.m-t-100 {
    margin-top: 100px;
  }
}

.blog__title {
  font-size: 26px;
  font-weight: 400;
}

@media (min-width: 576px) {
  .blog__title {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .blog__title {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .blog__title {
    font-size: 36px;
  }
}

.blog-feed__img-box {
  overflow: hidden;
  position: relative;
}

.blog-feed__img--link {
  display: block;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.blog-feed__img--link:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.blog-feed__img {
  width: 100%;
  height: auto;
}

.blog-feed__content {
  background: #fff;
  padding: 25px 25px 0px 25px;
}

.blog-feed__link {
  font-size: 18px;
  line-height: 20px;
  color: #181818;
  text-transform: capitalize;
  display: block;
  margin-top: 25px;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-feed__link:hover {
  color: #e81212;
}

.blog-feed__post-meta--date {
  color: #777;
}

.blog-feed__excerpt {
  color: #777;
}

.blog.gray-bg .blog-feed__content {
  background: #fff;
  padding: 25px 20px 30px 20px;
}

.blog__img {
  overflow: hidden;
}

.blog__img a {
  display: block;
}

.blog__img img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 300px;
}

.blog__img iframe {
  width: 100%;
  height: 300px;
}

.blog__type-grid {
  margin-bottom: 40px;
}

.blog__type-list {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .blog__type-list {
    margin-bottom: 40px;
  }
}

.blog__type-list .blog__img {
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .blog__type-list .blog__img {
    margin-bottom: 0px;
  }
}

.blog__type-single .img-responsive {
  margin-bottom: 35px;
}

.blog~.page-pagination {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .blog~.page-pagination {
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .blog~.page-pagination {
    margin-top: 50px;
  }
}

.blog--comment .comment {
  padding: 30px;
}

.popup-icon i {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: blue;
  text-align: center;
  border-radius: 50%;
}

.popup-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none !important;
}

.blog__tag-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 15px;
}

@media (min-width: 768px) {
  .blog__tag-share {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

/*****************************
23 - Page - Cart
*****************************/
.cart-table-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 768px) {
  .cart-table-button {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.total-shipping {
  padding: 15px 0;
  border-top: 1px solid #777;
  border-bottom: 1px solid #777;
  margin-top: 20px;
}

.total-cost {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 300;
}

.total-cost span {
  font-weight: 500;
}

.shipping-cost li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}

.grand-total {
  color: #181818;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.empty-cart .btn:hover {
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
}

/*****************************
24 - Page - Checkout
*****************************/
.your-order-section {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .your-order-section {
    margin-top: 55px;
  }
}

@media (min-width: 992px) {
  .your-order-section {
    margin-top: 0px;
  }
}

.your-order-box {
  padding: 40px;
}

.your-order-middle {
  color: #666;
  padding: 20px 0;
  border-top: 1px solid #777;
  border-bottom: 1px solid #777;
  margin: 20px 0;
}

.your-order-total {
  padding: 20px 0;
  border-top: 1px solid #777;
  border-bottom: 1px solid #777;
  margin: 20px 0;
}

.payment-accordion h4 a {
  color: #181818;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  text-decoration: none;
}

.panel-body {
  color: #666;
  padding-left: 15px;
  font-size: 14px;
}

.open-create-account,
.open-shipping-account {
  display: none;
}

/*****************************
25 - Page - 404
*****************************/
.inner-input-icon input,
.page-not-found input {
  padding-right: 60px;
}

/*****************************
26 - Page - My Account
*****************************/
.account-wrapper {
  border: 2px solid #e5e5e5;
  padding: 40px 30px;
}

.my-account-menu .account-menu-list li a {
  display: inline-block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  font-size: 14px;
  color: #333;
  border: 1px solid #e5e5e5;
  margin-top: -1px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none;
}

.my-account-menu .account-menu-list li a:hover,
.my-account-menu .account-menu-list li a.active {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

.my-account-dashboard .welcome-dashboard p a:hover {
  color: #e52e06;
}

.my-account-tab .account-title {
  font-size: 24px;
  font-weight: 700;
  color: #333;
}

.my-account-details .account-details .title {
  font-size: 20px;
  font-weight: 700;
  color: #333;
}

.account-table .table thead tr th {
  border: 1px solid #e5e5e5;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  padding: 8px 15px;
}

.account-table .table tbody tr td {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
}

.account-table .table thead tr th.name {
  min-width: 140px;
}

.account-table .table thead tr th.date {
  min-width: 130px;
}

.single-form select,
.single-form textarea,
.single-form input {
  width: 100%;
  font-size: 14px;
  height: 40px;
  color: #333;
  border: 1px solid #e5e5e5;
  padding: 0 15px;
  margin-top: 12px;
  outline: none;
}

/*****************************
27 - Page - Login
*****************************/
.login-register-tab-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 40px;
}

.login-register-tab-list h4 {
  font-size: 25px;
  font-weight: 500;
  margin: 0 20px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #181818;
}

.login-register-tab-list .active h4 {
  color: #0063d1;
}

.login-register-tab-list a {
  position: relative;
  text-decoration: none !important;
}

.login-register-tab-list a::before {
  background-color: #242424;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-tab-list a:last-child::before {
  display: none;
}

.login-form-container {
  padding: 15px;
  text-align: left;
}

/*****************************
28 - Page - Contact
*****************************/
#map iframe {
  width: 100%;
  border: none;
  height: 600px;
}

@media (min-width: 576px) {
  #map iframe {
    height: 400px;
  }
}

@media (min-width: 360px) {
  #map iframe {
    height: 340px;
  }
}

.contact-info-wrap,
.contact-form {
  padding: 25px 20px;
}

@media (min-width: 576px) {

  .contact-info-wrap,
  .contact-form {
    padding: 25px 30px;
  }
}

@media (min-width: 768px) {

  .contact-info-wrap,
  .contact-form {
    padding: 30px 40px;
  }
}

.single-contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.contact-icon i {
  margin-right: 20px;
  width: 35px;
  height: 35px;
  border: 1px solid #ddd;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
}

.contact-info-dec a,
.contact-info-dec span {
  display: block;
  color: #181818;
  text-decoration: none !important;
}

.social-link li {
  display: inline-block;
  margin-right: 15px;
}

.social-link a {
  color: #181818;
  font-size: 22px;
}

/*****************************
29 - Page - FAQ
*****************************/
.accordian {
  width: 100%;
}

.accordian-item {
  margin-bottom: 20px;
}

.accordian-item:last-child {
  margin-bottom: 0;
}

.accordian-item-header {
  padding: 15px 45px 15px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #eef0f1;
  color: #181818;
}

.accordian-item-header.active {
  background: #e81212;
  color: #fff;
}

.accordian-item-header.active::after {
  content: "−";
}

.accordian-item-header h4 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
}

.accordian-item-header::after {
  content: "+";
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  right: 1rem;
}

.accordian-item-body {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
}

.accordian-item-body-content {
  padding: 1rem;
  line-height: 1.5;
  border: 1px solid #e0e0e0;
}

/*****************************
30 - Page - Contact
*****************************/
.about-top {
  margin-top: 60px;
}

.about-content {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .about-top .about-content {
    margin-top: 0;
  }
}

.skill-progressbar {
  margin-bottom: 35px;
}

.skill-progressbar:last-child {
  margin-bottom: 0;
}

.team-single-body {
  background-color: #ffffff;
}

.team-single {
  /* background: #fff; */
  margin: 0 auto;
  text-align: center;
  padding: 20px 30px 45px 30px;
}

.team-img img {
  margin: 0 auto;
}

.team-social {
  width: 100%;
  background: #fff;
  bottom: -22px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.team-social li {
  display: inline-block;
  margin-right: 10px;
}

.team-social li:last-child {
  margin-right: 30px;
}

.team-social a {
  color: #181818;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team-social a:hover {
  color: #e81212;

}

.team-single:hover .team-social {
  bottom: -15px;
  opacity: 1;
  visibility: visible;
}

/*****************************
31 - Layout - Header
*****************************/
.header__mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #546583;
  padding: 10px 0;
}

.header__mobile i {
  color: #fff;
  font-size: 22px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .header__mobile i {
    font-size: 26px;
  }
}

.header__mobile i:hover {
  color: #e81212;
}

.header__mobile-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__mobile-logo {
  width: 135px;
}

.header__mobile--rightside li,
.header__mobile--leftside li {
  margin-right: 20px;
}

@media (min-width: 576px) {

  .header__mobile--rightside li,
  .header__mobile--leftside li {
    margin-right: 30px;
  }
}

.header__mobile--rightside li:last-child,
.header__mobile--leftside li:last-child {
  margin-right: 0;
}

.header__logo--1 {
  width: 30%;
  margin-right: 105px;
}

.header__search-form {
  position: relative;
}

.header__search-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  color: #fff;
}

.header__search-input button {
  color: #fff;
  margin-right: 10px;
}

.header__search-input button i {
  font-size: 26px;
}

.header__search-input input[type=search] {
  width: 100%;
  height: 44px;
  padding: 10px 10px 10px 10px;
  outline: none;
  border: none;
  background: transparent;
  color: #fff;
}

.header__search-input input[type=search]::-webkit-input-placeholder {
  color: #fff;
}

.header__search-input input[type=search]::-moz-placeholder {
  color: #fff;
}

.header__search-input input[type=search]:-ms-input-placeholder {
  color: #fff;
}

.header__search-input input[type=search]::-ms-input-placeholder {
  color: #fff;
}

.header__search-input input[type=search]::placeholder {
  color: #fff;
}

.header__search-input-white-bg input[type=search] {
  color: #181818;
}

.header__search-input-white-bg input[type=search]::-webkit-input-placeholder {
  color: #000;
}

.header__search-input-white-bg input[type=search]::-moz-placeholder {
  color: #000;
}

.header__search-input-white-bg input[type=search]:-ms-input-placeholder {
  color: #000;
}

.header__search-input-white-bg input[type=search]::-ms-input-placeholder {
  color: #000;
}

.header__search-input-white-bg input[type=search]::placeholder {
  color: #000;
}

.header__search-input--mobile input[type=search] {
  padding: 10px 10px 10px 140px;
}

@media (min-width: 576px) {
  .header__search-input--mobile input[type=search] {
    padding: 10px 10px 10px 184px;
  }
}

.header__search-category {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header__search-category select {
  height: 45px;
  width: 150px;
  border: none;
  background: transparent;
  font-size: 14px;
  padding: 10px;
  outline: none;
}

.header__search-category--mobile select {
  width: 125px;
}

@media (min-width: 576px) {
  .header__search-category--mobile select {
    width: 150px;
  }
}

.header__user-action-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-weight: 600;
}

.header__user-action-icon li {
  margin-right: 27px;
}

.header__user-action-icon li:last-child {
  margin-right: 0;
}

.header__user-action-icon a {
  text-decoration: none;
  color: #fff;
}

.header__user-action-icon i {
  font-size: 24px;
  color: #fff;
}

.header__color--white .header__user-action-icon i {
  color: #181818;
}

.header__popup-search-form {
  position: absolute;
  top: 30px;
  right: 0;
  display: none;
  z-index: 99;
}

.header__popup-search-form input {
  padding: 10px 10px;
  width: 200px;
}

@media (min-width: 576px) {
  .header__popup-search-form input {
    padding: 10px 10px;
    width: 250px;
  }
}

.header__popup-search-form button {
  padding: 0 15px;
}

@media (min-width: 576px) {
  .header__popup-search-form button {
    padding: 0 20px;
  }
}

.header__popup-search-form i {
  color: #fff !important;
}

.header__tag {
  padding: 12px 0;
  background: #181818;
}

.header__tag-title {
  margin-bottom: 0;
  font-size: 13px;
  color: #fff;
}

.wishlist-item-count {
  bottom: 2px;
  right: -9px;
  color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
}

.wishlist-item-count--red {
  background: #e81212;
}

.wishlist-item-count--orange {
  background: #f17b08;
}

.wishlist-item-count--golden {
  background: #b09e64;
}

.user-set-role {
  margin-right: 20px;
  font-size: 13px;
}

.user-set-role:last-child {
  margin-right: 0;
}

.user-set-role__button {
  border: none;
  background: transparent;
  position: relative;
  color: #181818;
}

.user-set-role__button img {
  margin-right: 3px;
}

.user-set-role__button i {
  margin-left: 3px;
}

.user-set-role__button:hover {
  color: #0063d1;
  text-decoration: none;
}

.expand-dropdown-menu {
  position: absolute;
  z-index: 999;
  right: 0;
  left: auto;
  min-width: 150px;
  overflow: hidden;
  padding: 0 20px;
  background: #fff;
  border-bottom: 2px solid #546583;
  -webkit-box-shadow: 0px 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 13.95px 1.05px rgba(0, 0, 0, 0.05);
}

.expand-dropdown-menu li {
  padding: 0;
  border-bottom: 1px solid #e5e5e5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.expand-dropdown-menu a {
  display: block;
  padding: 10px 0;
  color: #7e7e7e;
  line-height: 25px;
  font-size: 13px;
  text-decoration: none;
}

.expand-dropdown-menu a img {
  margin-right: 3px;
}

.expand-dropdown-menu a:hover {
  color: #0063d1;
}

.expand-dropdown-menu.dropdown-menu {
  margin: 0;
}

.expand-dropdown-menu.dropdown-menu.show {
  top: 100% !important;
}

.header__color--white {
  background: rgba(255, 255, 255, 0.95);
}

.sticky-header {
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.sticky-header.is-sticky {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  margin-top: 0;
  z-index: 99999;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sticky-header.is-sticky.header__color--silver {
  background: rgba(84, 101, 131, 0.95);
}

.header__nav>.header__nav-item>.header__nav-link {
  transition: color .5s;

  &:focus {
    color: #ff4800;
  }
}

.header__nav>.header__nav-item>.header__nav-link.active {
  color: #fb6a21;
}

/* ----------------footer------------ */
.footer__nav>.footer__list>.footer__link {
  transition: color .5s;

  &:focus {
    color: #ff4800;
  }
}

.footer__nav>.footer__list>.footer__link.active {
  color: #fb6a21;
}

.header__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}

.header__nav-item {
  padding-right: 30px;
  display: inline-block;
}

@media (min-width: 1566px) {
  .header__nav-item {
    padding-right: 40px;
  }
}

.header__nav-item:last-child {
  padding-right: 0;
}

.header__nav-link {
  display: block;
  height: 35px;
  line-height: 60px;
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.header__nav-link i {
  margin-left: 5px;
}

.header__nav-link::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 0%;
  bottom: 15px;
  left: auto;
  right: 0;
  -webkit-transition: all 0.3s color .5s ease;
  transition: all 0.3s ease;
  color: #fb6a21;
}

.header__nav-link--white {
  color: #fff;
}

.header__nav-link--black {
  color: #000000;
}

/* .header__nav-link--white-hover-white::after {
  background: #fff;
} */

.header__nav-link--black-hover-black::after {
  background: #000000;
}

.header__nav-link--black {
  color: #181818;
}

.header__nav-link--black-hover-golden::after {
  background: #b09e64;
}

.header__nav-link--black-hover-orange::after {
  background: #f16508;
}

.header__nav-item:hover .header__nav-link--white-hover-white {
  /* color: #fff; */
  color: #ff7214;

}

.header__nav-item:hover .header__nav-link--black-hover-golden {
  color: #b09e64;
}

.header__nav-item:hover .header__nav-link--black-hover-orange {
  color: #f17b08;
}

.header__nav-item:hover .header__nav-link::after {
  width: 100%;
  left: 0;
  right: auto;
}

.dropdown__menu,
.dropdown__submenu {
  top: 100%;
  left: 0;
  width: 230px;
  background: #fff;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  border-bottom: 2px solid #546583;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 14px;
}

.toggle-sub-nav {
  top: 100%;
  left: 0;
  width: 320px;
  background: #fff;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  border-bottom: 1px solid #546583;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.toggle-sub-nav li .dropdown__submenu-link:hover {
  color: #ff5100;
}

.dropdown__submenu {
  top: 0;
  left: -100%;
}

@media (min-width: 1566px) {
  .dropdown__submenu {
    left: 100%;
  }
}

.dropdown__list,
.dropdown__submenu-list {
  padding: 5px 15px;
  display: block;
  position: relative;
}

.dropdown__link,
.dropdown__submenu-link {
  text-decoration: none !important;
  display: inline-block;
  width: 100%;
  color: #252525;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dropdown__link:hover,
.dropdown__submenu-link:hover {
  color: #f01111;
}

.mega-menu {
  top: 100%;
  left: 50%;
  margin: 0 auto;
  width: 720px;
  background: #fff;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  padding: 30px;
  text-transform: uppercase;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateX(-45%) rotateX(-90deg);
  transform: translateX(-45%) rotateX(-90deg);
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
  border-bottom: 2px solid #546583;
}

@media (min-width: 1566px) {
  .mega-menu {
    -webkit-transform: translateX(0%) rotateX(-90deg);
    transform: translateX(0%) rotateX(-90deg);
    left: 0;
    right: 0;
  }
}

.mega-menu__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mega-menu__item-box {
  margin-right: 30px;
}

.mega-menu__item-box:last-child {
  margin-right: 0;
}

.mega-menu__title {
  display: block;
  font-weight: 600;
}

.mega-menu__item {
  margin-top: 15px;
}

.mega-menu__item a {
  color: #252525;
  font-size: 14px;
}

.mega-menu__item p {
  color: #666666;
  font-size: 12px;
  line-height: 18px;
}

.mega-menu__list {
  padding-bottom: 5px;
}

.mega-menu__list:last-child {
  margin-bottom: 0;
}

.mega-menu__link {
  text-decoration: none !important;
  display: inline-block;
  width: 100%;
  color: #252525;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.mega-menu__link:hover {
  color: #e81212;
}

.mega-menu__banner {
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
}

.mega-menu__banner:hover .mega-menu__banner-img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.mega-menu__banner-link {
  display: block;
}

.mega-menu__banner-img {
  width: 100%;
  height: auto;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.toggle-Nav>li>.dropdown__submenu-link:hover {
  color: #ff4800;
}

.Offcanvas.Body .toggle-Nav>.footer__link:hover {
  color: #ff4800;
}

.header__nav-item:hover .dropdown__menu,
.dropdown__list:hover .dropdown__submenu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.header__nav-item:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: translateX(-45%) rotateX(0deg);
  transform: translateX(-45%) rotateX(0deg);
}

@media (min-width: 1566px) {
  .header__nav-item:hover .mega-menu {
    -webkit-transform: translateX(0%) rotateX(0deg);
    transform: translateX(0%) rotateX(0deg);
  }
}

.popup-expand-menu-button i {
  color: #fff;
  font-size: 40px;
}

.popup-expand-menu-button i:hover {
  color: #f17b08;
}

.popup-expand-menu {
  display: none;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

/*****************************
32 - Layout - Mobile Menu
*****************************/
.offside {
  background: #181818;
  overflow-y: hidden;
}

.offside-button {
  border: none;
  background: transparent;
  font-size: 32px;
  outline: none !important;
}

.offcanvas {
  padding: 40px 20px;
}

.offcanvas--open {
  border: none;
  background: transparent;
  font-size: 32px;
  outline: none !important;
}

.offcanvas-menu li {
  position: relative;
  padding: 5px 5px;
}

.offcanvas-menu>ul>li {
  border-bottom: 1px solid transparent;
}

.offcanvas-menu>ul>li:last-child {
  border-bottom: none;
}

.offcanvas-menu li a {
  display: block;
  color: #fff;
  text-decoration: none;
}

.offcanvas-menu li a:hover {
  color: #ec4f15;
}

.offcanvas-menu li .sub-menu {
  display: none;
}

.offcanvas__menu-expand::after {
  content: "\f105";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  right: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  color: #fff;
}

.offcanvas-menu .active>.offcanvas__menu-expand,
.sub-menu>.active .offcanvas__menu-expand {
  border-bottom: 1px solid #ddd;
}

.offcanvas-menu .active>.offcanvas__menu-expand:last-child,
.sub-menu>.active .offcanvas__menu-expand:last-child {
  border-bottom: none;
}

.offcanvas-menu .active>.offcanvas__menu-expand::after,
.sub-menu>.active .offcanvas__menu-expand::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #e81212;
}

.offcanvas-shop-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.offcanvas-shop-link a {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
  margin-right: 15px;
}

.offcanvas-shop-link a:last-child {
  margin-right: 0;
}

.offcanvas-shop-link a:hover {
  color: #e81212;
}

.offcanvas__logo {
  margin-bottom: 30px;
  text-align: left;
}

@media (min-width: 992px) {
  .offcanvas__logo {
    text-align: center;
  }
}

.offcanvas__logo-img {
  width: 120px;
  height: auto;
}

.offcanvas__address {
  text-align: left;
}

@media (min-width: 992px) {
  .offcanvas__address {
    text-align: center;
  }
}

.offcanvas__address-item {
  margin-bottom: 6px;
  color: #666 !important;
}

.offcanvas__address-item:last-child {
  margin-bottom: 0px;
}

.offcanvas__address-item span {
  color: #0063d1;
}

.offcanvas__address-item a {
  color: #666;
}

.offcanvas__address-item a:hover {
  color: #0063d1;
}

.offcanvas .offcanvas__social-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 15px;
}

.offcanvas .offcanvas__social-list {
  margin-right: 15px;
}

.offcanvas .offcanvas__social-list:last-child {
  margin-right: 0;
}

.offcanvas .offcanvas__social-link {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #666;
}

.offcanvas .offcanvas__social-link:hover {
  color: #e81212;
}

/*****************************
33 - Layout - Vertical Menu
*****************************/
.header-menu-vertical .menu-title {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
  background: #0063d1;
  padding: 15px 20px;
  border-radius: 3px;
  display: block;
}

.header-menu-vertical .menu-content {
  width: 100%;
  background: #fff;
  border: 1px solid #eaeaea;
  top: 100%;
  left: 0;
  display: none;
}

@media (min-width: 992px) {
  .header-menu-vertical .menu-content {
    display: block;
  }
}

.header-menu-vertical .menu-content li {
  display: block;
  position: relative;
}

.header-menu-vertical .menu-content li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
}

.header-menu-vertical .menu-content li a {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
  background: #fff;
  color: #47494a;
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-menu-vertical .menu-content li a:hover {
  color: #0063d1;
}

.header-menu-vertical .menu-content li.expand a i {
  padding-right: 15px;
  font-weight: 700;
}

.header-menu-vertical .menu-content li .sub-menu {
  position: absolute;
  z-index: 9;
  text-align: left;
  top: 0;
  left: 100%;
  background: #fff;
  padding: 15px 10px 15px 10px;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border: 1px solid #eaeaea;
  width: 705px;
}

@media (min-width: 1200px) {
  .header-menu-vertical .menu-content li .sub-menu {
    width: 768px;
  }
}

.header-menu-vertical .menu-content li .sub-menu>li {
  width: 33.33%;
  float: left;
}

.header-menu-vertical .menu-content li .sub-menu li a {
  margin: 5px 0px 5px 0px;
}

.header-menu-vertical .menu-content li .sub-menu.sub-menu-2 {
  padding: 0;
  width: 240px;
  border: 1px solid #eaeaea;
  background: #fff;
}

.header-menu-vertical .menu-content li .sub-menu.sub-menu-2 li {
  width: 100%;
  padding: 0;
  line-height: 20px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  display: block;
}

/*****************************
34 - Layout - Footer
*****************************/
.footer {
  background-color: #181818;
  font-size: 14px;
  color: #888;
}

.footer.m-t-100 {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .footer.m-t-100 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .footer.m-t-100 {
    margin-top: 100px;
  }
}

.footer__top {
  padding-top: 60px;
  padding-bottom: 16px;
}

@media (min-width: 768px) {
  .footer__top {
    padding-top: 80px;
    padding-bottom: 16px;
  }
}

@media (min-width: 992px) {
  .footer__top {
    padding-top: 80px;
    padding-bottom: 16px;
  }
}

.footer__top [class^=col] {
  margin-bottom: 40px;
}

.footer__top [class^=col]:last-child {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .footer__top [class^=col] {
    margin-bottom: 40px;
  }

  .footer__top [class^=col]:nth-child(3),
  .footer__top [class^=col]:nth-child(4) {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .footer__top [class^=col] {
    margin-bottom: 0;
  }
}

.footer__logo {
  margin-bottom: 30px;
  text-align: left;
}

@media (min-width: 992px) {
  .footer__logo {
    text-align: center;
  }
}

.footer__logo-img {
  width: 120px;
  height: auto;
}

.footer__address {
  margin-top: 0px;
}

@media (min-width: 992px) {
  .footer__address {
    text-align: justify;
  }
}

.footer__address-item {
  margin-bottom: 6px;
  color: #e0e0e0 !important;
  text-align: justify;
  padding-left: 0px;
}

.footer__address-item:last-child {
  margin-bottom: 0px;
}

.footer .footer__social-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 15px;
}

@media (min-width: 992px) {
  .footer .footer__social-nav {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.footer .footer__social-list {
  margin-right: 15px;
}

.footer .footer__social-list:last-child {
  margin-right: 0;
}

.footer .footer__social-link {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #e0e0e0;
}

.footer .footer__social-link:hover {
  color: #e81212;
}

.aakaarInfotech {
  text-decoration: none;
  color: #e81212;
}

.footer__nav {
  padding-left: 0px;
}

.footer__nav-title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  text-align: justify;
}

.footer__nav-title--dash {
  position: relative;
}

.footer__nav-title--dash::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 10px;
}

.footer__nav-title--dash-red::after {
  background: #e81212;
}

.footer__nav-title--dash-orange::after {
  background: #f17b08;
}

.footer__nav-title--dash-golden::after {
  background: #b09e64;
}

.footer__list {
  margin-bottom: 12px;
}

.footer__list:last-child {
  margin-bottom: 0px;
}

.footer__link {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 14px;
  text-decoration: none !important;
  display: block;
  font-weight: 500;

}

.footer__nav-title .footer__nav {
  padding-left: 0px;
}

.footer__link:hover {
  color: #e81212;
}

.footer__form-box {
  font-size: 16px;
  margin-top: 50px;
  position: relative;
}

.footer__form-box input {
  width: 100%;
  padding: 12px 120px 12px 10px;
  outline: none;
  border: none;
  background: #242424;
  border-right: none;
  color: #e0e0e0;
}

.footer__form-box button {
  position: absolute;
  padding: 16px 10px;
  right: 0;
}

.footer__bottom {
  padding: 10px 0;
  border-top: 1px solid #666;
}

.footer__bottom-copyright {
  padding: 10px 0;
  border-top: 1px solid #666;
}

.footer__copyright-text {
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .footer__copyright-text {
    text-align: left;
    margin-bottom: 0;
  }
}

.footer__copyright-text p {
  margin: 0;
}

.footer__copyright-text a {
  color: #888;
}

.footer__copyright-text a:hover {
  color: #e81212;
}

.footer__payment {
  overflow: hidden;
  text-align: center;
}

@media (min-width: 992px) {
  .footer__payment {
    text-align: right;
  }
}

.footer__payment-link {
  display: block;
}

.footer__payment-img {
  max-width: 100%;
}



/*****************************
35 - Layout - Custom Layout
*****************************/
.container {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1566px) {
  .container {
    max-width: 1440px;
  }
}

.container-fluid {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  overflow-x: hidden;
}

/*****************************
36 - Layout - Table
*****************************/
table {
  border: 1px solid #e0e0e0;
  width: 100%;
}

table thead>tr {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
}

table thead>tr>th {
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 600;
  padding: 21px 45px 22px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
}

table tbody>tr {
  border-bottom: 1px solid #e0e0e0;
}

table tbody>tr td {
  color: #181818;
  font-size: 15px;
  padding: 30px 15px;
  text-align: center;
}

table tbody>tr td a {
  color: #181818;
  text-decoration: none !important;
  display: inline-block;
}

table tbody>tr td a:hover {
  color: #e81212;
}

.product-thumbnail {
  width: 150px;
}

.product-name {
  width: 435px;
}

.product-price-cart {
  width: 435px;
}

.product-quantities {
  width: 435px;
}

.product-subtotal {
  width: 435px;
}

.product-remove {
  width: 100px;
}

.product-remove a {
  color: #666;
  font-size: 16px;
  margin: 0 10px;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}

.first-column {
  min-width: 160px;
  font-size: 18px;
  font-weight: 600;
  color: #181818;
  margin: 0;
  line-height: 1;
}

.product-image-title {
  min-width: 300px;
  vertical-align: bottom;
  padding-top: 70px;
}

.image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
  display: block;
}

.category {
  float: left;
  clear: both;
  font-size: 13px;
  line-height: 15px;
  color: #181818;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.pro-stock {
  font-size: 16px;
  font-weight: 600;
  color: #181818;
}

.pro-remove button:hover {
  color: #ed3c32;
}

/*****************************
37 - Layout - Offacnvas
*****************************/
/* Body Style When OffCanvas Open */
.offcanvas-close {
  color: #e81212;
}

.offcanvas-close i {
  font-size: 24px;
}

/* OffCanvas Overlay */

.offcanvas-overlay {
  z-index: 999;
  margin-left: 30px;
}

/* OffCanvas */
.offcanvas {
  font-size: 16px;
  font-weight: 400;
  z-index: 999999;
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  overflow: auto;
  width: 300px;
  height: 100vh;
  padding: 20px;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  background-color: #181818;
  -webkit-box-shadow: none;
  box-shadow: none;
  visibility: visible;
}

@media (min-width: 576px) {
  .offcanvas {
    width: 400px;
  }
}

.offcanvas.offcanvas-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.offcanvas__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #181818;
  text-transform: capitalize !important;
  margin-top: 10px;
  margin-bottom: 30px;
}

.offcanvas__top-text {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}

.offcanvas__top-text i {
  font-size: 20px;
  color: #181818;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
}

.offcanvas.offcanvas-mobile-menu {
  right: 0;
  padding: 40px 40px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.offcanvas.offcanvas-mobile-menu.offcanvas-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/*****************************
38 - Layout - Addcart Offcanvas
*****************************/
.offcanvas-add-cart {
  background: #fff;
  overflow-y: auto;
}

.offcanvas-add-cart__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  padding: 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}

.offcanvas-add-cart__list:last-child {
  margin-bottom: 0;
}

.offcanvas-add-cart__img-box {
  margin-right: 15px;
  overflow: hidden;
}

.offcanvas-add-cart__img {
  width: 100%;
}

.offcanvas-add-cart__link {
  display: block;
  color: #181818;
}

.offcanvas-add-cart__link:hover {
  color: #e81212;
}

.offcanvas-add-cart__detail span {
  display: block;
}

.offcanvas-add-cart__item-dismiss {
  width: 18px;
  height: 18px;
  background: #e81212;
  border-radius: 50%;
  line-height: 18px;
}

.offcanvas-add-cart__item-dismiss i {
  font-size: 14px;
  color: #fff;
}

.offcanvas-add-cart__item-count {
  left: 0;
  top: 0;
  color: #fff;
  background: #546583;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
}

.offcanvas-add-cart__price {
  color: #181818;
  font-weight: 600;
  font-size: 16px;
  margin: 5px 0;
}

.offcanvas-add-cart__info {
  font-size: 12px;
  color: #777;
}

.offcanvas-add-cart__checkout-list {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}

.offcanvas-add-cart__checkout-info {
  padding-top: 25px;
}

.offcanvas-add-cart__checkout-left-info {
  color: #777;
}

.offcanvas-add-cart__checkout-right-info {
  font-weight: 600;
  color: #546583;
}

.offcanvas-add-cart__btn-checkout {
  margin-top: 50px;
}

.offcanvas-add-cart__checkout-box-bottom {
  padding-bottom: 15px;
}

/*****************************
39 - Layout - Slider Customize
*****************************/
.gap__col--30 .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.gap__col--30 .slick-slide {
  margin-left: 15px;
  margin-right: 15px;
}

.gap__row--30 .slick-slide>div {
  margin-bottom: 30px;
}

.gap__row--30 .slick-slide>div:last-child {
  margin-bottom: 0;
}

.gap__row--40 .slick-slide>div {
  margin-bottom: 40px;
}

.gap__row--40 .slick-slide>div:last-child {
  margin-bottom: 0;
}

.slider-dot-fix .slick-dots li button {
  text-indent: -9999px;
  display: inline-block;
}

.dot-gap__X--5 .slick-dots li {
  margin-right: 5px;
}

.dot-gap__X--5 .slick-dots li:last-child {
  margin-right: 0;
}

.dot-gap__X--10 .slick-dots li {
  margin-right: 10px;
}

.dot-gap__X--10 .slick-dots li:last-child {
  margin-right: 0;
}

.dot-gap__Y--30 .slick-dots {
  margin-top: 30px;
}

.slider-dot .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slider-dot .slick-dots li {
  display: inline-block !important;
}

.slider-dot.slider-dot--start .slick-dots {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.slider-dot.slider-dot--center .slick-dots {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slider-dot-size--medium .slick-dots li button {
  width: 15px;
  height: 15px;
}

.slider-dot-size--small .slick-dots li button {
  width: 12px;
  height: 12px;
}

.slider-dot-circle .slick-dots li button {
  border-radius: 50%;
}

.slider-dot-style--border .slick-dots li button {
  border: 1px solid;
}

.slider-dot-style--border-black-active-red .slick-dots li button {
  border-color: #181818;
}

.slider-dot-style--border-black-active-red .slick-dots li.slick-active button {
  border-color: #e81212;
}

.slider-dot-style--border-black-active-golden .slick-dots li button {
  border-color: #181818;
}

.slider-dot-style--border-black-active-golden .slick-dots li.slick-active button {
  border-color: #b09e64;
}

.slider-dot-style--fill .slick-dots li button {
  background: #fff;
}

.slider-dot-style--fill-white-active-white .slick-dots li button {
  background: rgba(255, 255, 255, 0.3);
}

.slider-dot-style--fill-white-active-white .slick-dots li.slick-active button {
  background: #fff;
}

.slider-dot-style--fill-white-active-golden .slick-dots li button {
  background: #fff;
}

.slider-dot-style--fill-white-active-golden .slick-dots li.slick-active button {
  background: #b09e64;
}

.hero-slider__arrow {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
}

.hero-slider__arrow--left {
  left: 20px;
}

.hero-slider__arrow--right {
  right: 20px;
}

.hero .slick-dots {
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.default-slider__arrow {
  width: 50px;
  height: 50px;
  background: #fff;
  color: #181818;
  font-size: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #181818;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 99;
}

.default-slider__arrow i {
  font-size: 24px;
}

@media (min-width: 992px) {
  .default-slider__arrow {
    display: block;
  }
}

.default-slider__arrow--left {
  left: 0;
}

@media (min-width: 1566px) {
  .default-slider__arrow--left {
    left: calc(0% - 55px);
  }
}

.default-slider__arrow--right {
  right: 0;
}

@media (min-width: 1566px) {
  .default-slider__arrow--right {
    right: calc(0% - 55px);
  }
}

.default-slider:hover .default-slider__arrow {
  opacity: 1;
  visibility: visible;
}

.default-slider:hover .default-slider__arrow--left {
  left: calc(0% - 25px);
}

@media (min-width: 992px) {
  .default-slider:hover .default-slider__arrow--left {
    left: calc(0% - 15px);
  }
}

@media (min-width: 1566px) {
  .default-slider:hover .default-slider__arrow--left {
    left: calc(0% - 65px);
  }
}

.default-slider:hover .default-slider__arrow--right {
  right: calc(0% - 25px);
}

@media (min-width: 992px) {
  .default-slider:hover .default-slider__arrow--right {
    right: calc(0% - 15px);
  }
}

@media (min-width: 1566px) {
  .default-slider:hover .default-slider__arrow--right {
    right: calc(0% - 65px);
  }
}

.default-slider--hover-bg-red .default-slider__arrow:hover {
  color: #fff;
  background: #e81212;
  border-color: #e81212;
}

.default-slider--hover-bg-red .default-slider__arrow.slick-disabled {
  background: #fff;
  color: #181818;
  border: 1px solid #181818;
}

.default-slider--hover-bg-orange .default-slider__arrow:hover {
  color: #fff;
  background: #f17b08;
  border-color: #f17b08;
}

.default-slider--hover-bg-orange .default-slider__arrow.slick-disabled {
  background: #fff;
  color: #181818;
  border: 1px solid #181818;
}

.default-slider--hover-bg-golden .default-slider__arrow:hover {
  color: #fff;
  background: #b09e64;
  border-color: #b09e64;
}

.default-slider--hover-bg-golden .default-slider__arrow.slick-disabled {
  background: #fff;
  color: #181818;
  border: 1px solid #181818;
}

.product-default-slider .slick-slide {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.product-default-slider .slick-slide.slick-active {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.product-segment-slider .slick-slide .product__box--segment {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.product-segment-slider .slick-slide.slick-active .product__box--segment {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.gallery__nav {
  position: absolute;
  color: #777;
  z-index: 99;
}

.gallery__nav-horizontal {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gallery__nav-horizontal--right {
  right: -15px;
}

.gallery__nav-horizontal--left {
  left: -15px;
}

.gallery__nav-vertical {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.gallery__nav-vertical--up {
  top: -20px;
}

.gallery__nav-vertical--down {
  bottom: -20px;
}

.inner-slider__arrow {
  width: 30px;
  height: 30px;
  background: #fff;
  color: #181818;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #181818;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 99;
  line-height: 18px;
}

.inner-slider__arrow i {
  font-size: 18px;
}

.inner-slider__arrow--left {
  left: calc(0% + 5px);
}

.inner-slider__arrow--right {
  right: calc(0% + 5px);
}

.inner-slider:hover .inner-slider__arrow {
  opacity: 1;
  visibility: visible;
}

.inner-slider:hover .inner-slider__arrow--left {
  left: calc(0% + 15px);
}

.inner-slider:hover .inner-slider__arrow--right {
  right: calc(0% + 15px);
}

.inner-slider-grid-4 .slick-list {
  margin-right: -20px;
}

.inner-slider-grid-4 .slick-slide {
  margin-right: 20px;
}

/*****************************
40 - Layout - Utilities
*****************************/
/*:::::::::: Margin Top :::::::::: */
.m-t-0 {
  margin-top: 0px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-55 {
  margin-top: 55px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-t-65 {
  margin-top: 65px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-t-75 {
  margin-top: 75px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-85 {
  margin-top: 85px;
}

.m-t-90 {
  margin-top: 90px;
}

.m-t-95 {
  margin-top: 95px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-t-105 {
  margin-top: 105px;
}

.m-t-110 {
  margin-top: 110px;
}

.m-t-115 {
  margin-top: 115px;
}

.m-t-120 {
  margin-top: 120px;
}

.m-t-125 {
  margin-top: 125px;
}

.m-t-130 {
  margin-top: 130px;
}

.m-t-135 {
  margin-top: 135px;
}

.m-t-140 {
  margin-top: 140px;
}

.m-t-145 {
  margin-top: 145px;
}

.m-t-150 {
  margin-top: 150px;
}

.m-t-155 {
  margin-top: 155px;
}

.m-t-160 {
  margin-top: 160px;
}

/*:::::::::: Margin bottom :::::::::: */
.m-b-0 {
  margin-bottom: 0px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-b-55 {
  margin-bottom: 55px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-b-65 {
  margin-bottom: 65px;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-b-75 {
  margin-bottom: 75px;
}

.m-b-80 {
  margin-bottom: 80px;
}

.m-b-85 {
  margin-bottom: 85px;
}

.m-b-90 {
  margin-bottom: 90px;
}

.m-b-95 {
  margin-bottom: 95px;
}

.m-b-100 {
  margin-bottom: 100px;
}

.m-b-105 {
  margin-bottom: 105px;
}

.m-b-110 {
  margin-bottom: 110px;
}

.m-b-115 {
  margin-bottom: 115px;
}

.m-b-120 {
  margin-bottom: 120px;
}

.m-b-125 {
  margin-bottom: 125px;
}

.m-b-130 {
  margin-bottom: 130px;
}

.m-b-135 {
  margin-bottom: 135px;
}

.m-b-140 {
  margin-bottom: 140px;
}

.m-b-145 {
  margin-bottom: 145px;
}

.m-b-150 {
  margin-bottom: 150px;
}

.m-b-155 {
  margin-bottom: 155px;
}

.m-b-160 {
  margin-bottom: 160px;
}

/*:::::::::: Margin Left :::::::::: */
.m-l-0 {
  margin-left: 0px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-l-55 {
  margin-left: 55px;
}

.m-l-60 {
  margin-left: 60px;
}

.m-l-65 {
  margin-left: 65px;
}

.m-l-70 {
  margin-left: 70px;
}

.m-l-75 {
  margin-left: 75px;
}

.m-l-80 {
  margin-left: 80px;
}

.m-l-85 {
  margin-left: 85px;
}

.m-l-90 {
  margin-left: 90px;
}

.m-l-95 {
  margin-left: 95px;
}

.m-l-100 {
  margin-left: 100px;
}

.m-l-105 {
  margin-left: 105px;
}

.m-l-110 {
  margin-left: 110px;
}

.m-l-115 {
  margin-left: 115px;
}

.m-l-120 {
  margin-left: 120px;
}

.m-l-125 {
  margin-left: 125px;
}

.m-l-130 {
  margin-left: 130px;
}

.m-l-135 {
  margin-left: 135px;
}

.m-l-140 {
  margin-left: 140px;
}

.m-l-145 {
  margin-left: 145px;
}

.m-l-150 {
  margin-left: 150px;
}

.m-l-155 {
  margin-left: 155px;
}

.m-l-160 {
  margin-left: 160px;
}

/*:::::::::: Margin Right :::::::::: */
.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-r-55 {
  margin-right: 55px;
}

.m-r-60 {
  margin-right: 60px;
}

.m-r-65 {
  margin-right: 65px;
}

.m-r-70 {
  margin-right: 70px;
}

.m-r-75 {
  margin-right: 75px;
}

.m-r-80 {
  margin-right: 80px;
}

.m-r-85 {
  margin-right: 85px;
}

.m-r-90 {
  margin-right: 90px;
}

.m-r-95 {
  margin-right: 95px;
}

.m-r-100 {
  margin-right: 100px;
}

.m-r-105 {
  margin-right: 105px;
}

.m-r-110 {
  margin-right: 110px;
}

.m-r-115 {
  margin-right: 115px;
}

.m-r-120 {
  margin-right: 120px;
}

.m-r-125 {
  margin-right: 125px;
}

.m-r-130 {
  margin-right: 130px;
}

.m-r-135 {
  margin-right: 135px;
}

.m-r-140 {
  margin-right: 140px;
}

.m-r-145 {
  margin-right: 145px;
}

.m-r-150 {
  margin-right: 150px;
}

.m-r-155 {
  margin-right: 155px;
}

.m-r-160 {
  margin-right: 160px;
}

/*:::::::::: Margin Left Right :::::::::: */
.m-lr-0 {
  margin: 0 0px;
}

.m-lr-5 {
  margin: 0 5px;
}

.m-lr-10 {
  margin: 0 10px;
}

.m-lr-15 {
  margin: 0 15px;
}

.m-lr-20 {
  margin: 0 20px;
}

.m-lr-25 {
  margin: 0 25px;
}

.m-lr-30 {
  margin: 0 30px;
}

.m-lr-35 {
  margin: 0 35px;
}

.m-lr-40 {
  margin: 0 40px;
}

.m-lr-45 {
  margin: 0 45px;
}

.m-lr-50 {
  margin: 0 50px;
}

.m-lr-55 {
  margin: 0 55px;
}

.m-lr-60 {
  margin: 0 60px;
}

.m-lr-65 {
  margin: 0 65px;
}

.m-lr-70 {
  margin: 0 70px;
}

.m-lr-75 {
  margin: 0 75px;
}

.m-lr-80 {
  margin: 0 80px;
}

.m-lr-85 {
  margin: 0 85px;
}

.m-lr-90 {
  margin: 0 90px;
}

.m-lr-95 {
  margin: 0 95px;
}

.m-lr-100 {
  margin: 0 100px;
}

.m-lr-105 {
  margin: 0 105px;
}

.m-lr-110 {
  margin: 0 110px;
}

.m-lr-115 {
  margin: 0 115px;
}

.m-lr-120 {
  margin: 0 120px;
}

.m-lr-125 {
  margin: 0 125px;
}

.m-lr-130 {
  margin: 0 130px;
}

.m-lr-135 {
  margin: 0 135px;
}

.m-lr-140 {
  margin: 0 140px;
}

.m-lr-145 {
  margin: 0 145px;
}

.m-lr-150 {
  margin: 0 150px;
}

.m-lr-155 {
  margin: 0 155px;
}

.m-lr-160 {
  margin: 0 160px;
}

/*:::::::::: Margin Top Bottom :::::::::: */
.m-tb-0 {
  margin: 0px 0;
}

.m-tb-5 {
  margin: 5px 0;
}

.m-tb-10 {
  margin: 10px 0;
}

.m-tb-15 {
  margin: 15px 0;
}

.m-tb-20 {
  margin: 20px 0;
}

.m-tb-25 {
  margin: 25px 0;
}

.m-tb-30 {
  margin: 30px 0;
}

.m-tb-35 {
  margin: 35px 0;
}

.m-tb-40 {
  margin: 40px 0;
}

.m-tb-45 {
  margin: 45px 0;
}

.m-tb-50 {
  margin: 50px 0;
}

.m-tb-55 {
  margin: 55px 0;
}

.m-tb-60 {
  margin: 60px 0;
}

.m-tb-65 {
  margin: 65px 0;
}

.m-tb-70 {
  margin: 70px 0;
}

.m-tb-75 {
  margin: 75px 0;
}

.m-tb-80 {
  margin: 80px 0;
}

.m-tb-85 {
  margin: 85px 0;
}

.m-tb-90 {
  margin: 90px 0;
}

.m-tb-95 {
  margin: 95px 0;
}

.m-tb-100 {
  margin: 100px 0;
}

.m-tb-105 {
  margin: 105px 0;
}

.m-tb-110 {
  margin: 110px 0;
}

.m-tb-115 {
  margin: 115px 0;
}

.m-tb-120 {
  margin: 120px 0;
}

.m-tb-125 {
  margin: 125px 0;
}

.m-tb-130 {
  margin: 130px 0;
}

.m-tb-135 {
  margin: 135px 0;
}

.m-tb-140 {
  margin: 140px 0;
}

.m-tb-145 {
  margin: 145px 0;
}

.m-tb-150 {
  margin: 150px 0;
}

.m-tb-155 {
  margin: 155px 0;
}

.m-tb-160 {
  margin: 160px 0;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::
==================== Padding measurement===========
::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
/*:::::::::: Pargin Top :::::::::: */
.p-t-0 {
  padding-top: 0px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-t-55 {
  padding-top: 55px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-t-65 {
  padding-top: 65px;
}

.p-t-70 {
  padding-top: 70px;
}

.p-t-75 {
  padding-top: 75px;
}

.p-t-80 {
  padding-top: 80px;
}

.p-t-85 {
  padding-top: 85px;
}

.p-t-90 {
  padding-top: 90px;
}

.p-t-95 {
  padding-top: 95px;
}

.p-t-100 {
  padding-top: 100px;
}

.p-t-105 {
  padding-top: 105px;
}

.p-t-110 {
  padding-top: 110px;
}

.p-t-115 {
  padding-top: 115px;
}

.p-t-120 {
  padding-top: 120px;
}

.p-t-125 {
  padding-top: 125px;
}

.p-t-130 {
  padding-top: 130px;
}

.p-t-135 {
  padding-top: 135px;
}

.p-t-140 {
  padding-top: 140px;
}

.p-t-145 {
  padding-top: 145px;
}

.p-t-150 {
  padding-top: 150px;
}

.p-t-155 {
  padding-top: 155px;
}

.p-t-160 {
  padding-top: 160px;
}

/*:::::::::: Padding bottom :::::::::: */
.p-b-0 {
  padding-bottom: 0px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-35 {
  padding-bottom: 35px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.p-b-55 {
  padding-bottom: 55px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-b-65 {
  padding-bottom: 65px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.p-b-75 {
  padding-bottom: 75px;
}

.p-b-80 {
  padding-bottom: 80px;
}

.p-b-85 {
  padding-bottom: 85px;
}

.p-b-90 {
  padding-bottom: 90px;
}

.p-b-95 {
  padding-bottom: 95px;
}

.p-b-100 {
  padding-bottom: 100px;
}

.p-b-105 {
  padding-bottom: 105px;
}

.p-b-110 {
  padding-bottom: 110px;
}

.p-b-115 {
  padding-bottom: 115px;
}

.p-b-120 {
  padding-bottom: 120px;
}

.p-b-125 {
  padding-bottom: 125px;
}

.p-b-130 {
  padding-bottom: 130px;
}

.p-b-135 {
  padding-bottom: 135px;
}

.p-b-140 {
  padding-bottom: 140px;
}

.p-b-145 {
  padding-bottom: 145px;
}

.p-b-150 {
  padding-bottom: 150px;
}

.p-b-155 {
  padding-bottom: 155px;
}

.p-b-160 {
  padding-bottom: 160px;
}

/*:::::::::: Padding Left :::::::::: */
.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

.p-l-55 {
  padding-left: 55px;
}

.p-l-60 {
  padding-left: 60px;
}

.p-l-65 {
  padding-left: 65px;
}

.p-l-70 {
  padding-left: 70px;
}

.p-l-75 {
  padding-left: 75px;
}

.p-l-80 {
  padding-left: 80px;
}

.p-l-85 {
  padding-left: 85px;
}

.p-l-90 {
  padding-left: 90px;
}

.p-l-95 {
  padding-left: 95px;
}

.p-l-100 {
  padding-left: 100px;
}

.p-l-105 {
  padding-left: 105px;
}

.p-l-110 {
  padding-left: 110px;
}

.p-l-115 {
  padding-left: 115px;
}

.p-l-120 {
  padding-left: 120px;
}

.p-l-125 {
  padding-left: 125px;
}

.p-l-130 {
  padding-left: 130px;
}

.p-l-135 {
  padding-left: 135px;
}

.p-l-140 {
  padding-left: 140px;
}

.p-l-145 {
  padding-left: 145px;
}

.p-l-150 {
  padding-left: 150px;
}

.p-l-155 {
  padding-left: 155px;
}

.p-l-160 {
  padding-left: 160px;
}

/*:::::::::: padding Right :::::::::: */
.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

.p-r-55 {
  padding-right: 55px;
}

.p-r-60 {
  padding-right: 60px;
}

.p-r-65 {
  padding-right: 65px;
}

.p-r-70 {
  padding-right: 70px;
}

.p-r-75 {
  padding-right: 75px;
}

.p-r-80 {
  padding-right: 80px;
}

.p-r-85 {
  padding-right: 85px;
}

.p-r-90 {
  padding-right: 90px;
}

.p-r-95 {
  padding-right: 95px;
}

.p-r-100 {
  padding-right: 100px;
}

.p-r-105 {
  padding-right: 105px;
}

.p-r-110 {
  padding-right: 110px;
}

.p-r-115 {
  padding-right: 115px;
}

.p-r-120 {
  padding-right: 120px;
}

.p-r-125 {
  padding-right: 125px;
}

.p-r-130 {
  padding-right: 130px;
}

.p-r-135 {
  padding-right: 135px;
}

.p-r-140 {
  padding-right: 140px;
}

.p-r-145 {
  padding-right: 145px;
}

.p-r-150 {
  padding-right: 150px;
}

.p-r-155 {
  padding-right: 155px;
}

.p-r-160 {
  padding-right: 160px;
}

/*:::::::::: padding Left Right :::::::::: */
.p-lr-0 {
  padding: 0 0;
}

.p-lr-5 {
  padding: 0 5px;
}

.p-lr-10 {
  padding: 0 10px;
}

.p-lr-15 {
  padding: 0 15px;
}

.p-lr-20 {
  padding: 0 20px;
}

.p-lr-25 {
  padding: 0 25px;
}

.p-lr-30 {
  padding: 0 30px;
}

.p-lr-35 {
  padding: 0 35px;
}

.p-lr-40 {
  padding: 0 40px;
}

.p-lr-45 {
  padding: 0 45px;
}

.p-lr-50 {
  padding: 0 50px;
}

.p-lr-55 {
  padding: 0 55px;
}

.p-lr-60 {
  padding: 0 60px;
}

.p-lr-65 {
  padding: 0 65px;
}

.p-lr-70 {
  padding: 0 70px;
}

.p-lr-75 {
  padding: 0 75px;
}

.p-lr-80 {
  padding: 0 80px;
}

.p-lr-85 {
  padding: 0 85px;
}

.p-lr-90 {
  padding: 0 90px;
}

.p-lr-95 {
  padding: 0 95px;
}

.p-lr-100 {
  padding: 0 100px;
}

.p-lr-105 {
  padding: 0 105px;
}

.p-lr-110 {
  padding: 0 110px;
}

.p-lr-115 {
  padding: 0 115px;
}

.p-lr-120 {
  padding: 0 120px;
}

.p-lr-125 {
  padding: 0 125px;
}

.p-lr-130 {
  padding: 0 130px;
}

.p-lr-135 {
  padding: 0 135px;
}

.p-lr-140 {
  padding: 0 140px;
}

.p-lr-145 {
  padding: 0 145px;
}

.p-lr-150 {
  padding: 0 150px;
}

.p-lr-155 {
  padding: 0 155px;
}

.p-lr-160 {
  padding: 0 160px;
}

/*:::::::::: padding Top Bottom :::::::::: */
.p-tb-0 {
  padding: 0 0;
}

.p-tb-5 {
  padding: 5px 0;
}

.p-tb-10 {
  padding: 10px 0;
}

.p-tb-15 {
  padding: 15px 0;
}

.p-tb-20 {
  padding: 20px 0;
}

.p-tb-25 {
  padding: 25px 0;
}

.p-tb-30 {
  padding: 30px 0;
}

.p-tb-35 {
  padding: 35px 0;
}

.p-tb-40 {
  padding: 40px 0;
}

.p-tb-45 {
  padding: 45px 0;
}

.p-tb-50 {
  padding: 50px 0;
}

.p-tb-55 {
  padding: 55px 0;
}

.p-tb-60 {
  padding: 60px 0;
}

.p-tb-65 {
  padding: 65px 0;
}

.p-tb-70 {
  padding: 70px 0;
}

.p-tb-75 {
  padding: 75px 0;
}

.p-tb-80 {
  padding: 80px 0;
}

.p-tb-85 {
  padding: 85px 0;
}

.p-tb-90 {
  padding: 90px 0;
}

.p-tb-95 {
  padding: 95px 0;
}

.p-tb-100 {
  padding: 100px 0;
}

.p-tb-105 {
  padding: 105px 0;
}

.p-tb-110 {
  padding: 110px 0;
}

.p-tb-115 {
  padding: 115px 0;
}

.p-tb-120 {
  padding: 120px 0;
}

.p-tb-125 {
  padding: 125px 0;
}

.p-tb-130 {
  padding: 130px 0;
}

.p-tb-135 {
  padding: 135px 0;
}

.p-tb-140 {
  padding: 140px 0;
}

.p-tb-145 {
  padding: 145px 0;
}

.p-tb-150 {
  padding: 150px 0;
}

.p-tb-155 {
  padding: 155px 0;
}

.p-tb-160 {
  padding: 160px 0;
}

.border-top-1 {
  border-top: 1px solid #ddd;
}